let locationCart = {};
let updateCartProducts;
let updateCartSummary;
let updateMenuButtons;
let emptyCartOnModalClose = true;
let getClassicCartModal;
let emptyAllCart;
let screenOrientation = window.screen.orientation.type.includes("landscape")
  ? "LANDSCAPE"
  : "PORTRAIT";

function setClassicLocationCart(data) {
  if (data.location_id !== undefined && data.location_id !== null) {
    locationCart.location_id = data.location_id;
    if (data.location_type !== undefined && data.location_type !== null) {
      locationCart.location_type = data.location_type;
    }
    if (data.table_number !== undefined && data.table_number !== null) {
      locationCart.table_number = data.table_number;
    }
    if (
      data.number_of_products !== undefined &&
      data.number_of_products !== null
    ) {
      locationCart.number_of_products = data.number_of_products;
    }
    if (data.amount !== undefined && data.amount !== null) {
      locationCart.amount = data.amount;
    }
    if (data.tips_percent !== undefined && data.tips_percent !== null) {
      locationCart.tips_percent = data.tips_percent;
    }
    if (data.payment_method !== undefined && data.payment_method !== null) {
      locationCart.payment_method = data.payment_method;
    }
    if (data.comment !== undefined && data.comment !== null) {
      locationCart.comment = data.comment;
    }
    if (data.name !== undefined && data.name !== null) {
      locationCart.name = data.name;
    }
    if (data.phone_number !== undefined && data.phone_number !== null) {
      locationCart.phone_number = data.phone_number;
    }
    if (data.pickup_date !== undefined && data.pickup_date !== null) {
      locationCart.pickup_date = data.pickup_date;
    }
    if (data.pickup_time !== undefined && data.pickup_time !== null) {
      locationCart.pickup_time = data.pickup_time;
    }
    if (data.promo_code !== undefined && data.promo_code !== null) {
      locationCart.promo_code = data.promo_code;
    }
    if (
      data.pick_up_at_the_bar !== undefined &&
      data.pick_up_at_the_bar !== null
    ) {
      locationCart.pick_up_at_the_bar = data.pick_up_at_the_bar;
    }
    if (data.products !== undefined && data.products !== null) {
      for (let i = 0; i < data.products.length; i++) {
        let productObj = data.products[i];
        let productExtras = [];
        for (let j = 0; j < productObj.product_extra.length; j++) {
          let productExtraObj = productObj.product_extra[j];
          let productExtra = {
            product_extra_id: productExtraObj.product_extra_id,
            amount: productExtraObj.amount,
            discount_percent: productExtraObj.discount_percent,
          };
          productExtras.push(productExtra);
        }
        let product = {
          product_id: productObj.product_id,
          amount: productObj.amount,
          comment: productObj.comment,
          discount_percent: productObj.discount_percent,
          original_product_price: productObj.original_product_price,
        };
        if (productExtras.length) {
          product.product_extra = productExtras;
        }
        if (productObj.product_table_id) {
          product.product_table_id = productObj.product_table_id;
        }
        if (productObj.product_table_split_id) {
          product.product_table_split_id = productObj.product_table_split_id;
        }
        locationCart.products.push(product);
      }
    }

    if (
      data.order_storage_ids !== undefined &&
      data.order_storage_ids !== null
    ) {
      orderStorageIds = data.order_storage_ids;
    }
    updateCartSummary();
    updateMenuButtons();
    if (
      data.set_payment_failed !== undefined &&
      data.set_payment_failed !== null &&
      data.set_payment_failed === true
    ) {
      locationCart.payment_failed = true;
    }
    locationCart.order_storage_identifier = "";
    if (data.checkout_type !== undefined && data.checkout_type !== null) {
      locationCart.checkout_type = data.checkout_type;
    }
    if (
      data.table_customer_id !== undefined &&
      data.table_customer_id !== null
    ) {
      locationCart.table_customer_id = data.table_customer_id;
    }
    if (
      data.amount_without_discount !== undefined &&
      data.amount_without_discount !== null
    ) {
      locationCart.amount_without_discount = data.amount_without_discount;
    }
    getClassicCartModal();
    if (
      data.set_payment_failed !== undefined &&
      data.set_payment_failed !== null &&
      data.set_payment_failed === true
    ) {
      delete locationCart.payment_failed;
    }
  }
}

$(document).ready(function () {
  let p = $("body").attr("p");
  let posType = $("#location-products").data("pos-type");
  let shop_type = $("body").attr("shop_type") ?? null;
  let error_message = $("body").attr("error_message") ?? null;

  let splitOrderId = $("body").attr("order") ?? null;
  const organizerId = $("body").attr("organizer_id");

  if (p === "events" && posType === "classic") {
    if ($("#suggested-product div").length > 0) {
      $("#remove-suggested-product-container").attr("hidden", false);
    }
    $("header").hide();
    $(".container").css({
      width: "100%",
      "max-width": "2550px",
    });

    $("#footer").css("display", "none");

    $("#content-body").css("height", "calc(100% - 56px)");

    let locationId = $("input[name='event_id']").val();
    var containerWidth = $("#product-container").width();
    var containerHeight = $("#product-container").height();

    var productWidth = 210;
    var productHeight = 130;

    var productsPerRow = Math.floor(containerWidth / productWidth);
    var rowsInContainer = Math.floor(containerHeight / productHeight);

    var totalProducts = productsPerRow * rowsInContainer;

    const formData = new FormData();
    formData.append("total_products_per_page", totalProducts);
    formData.append("event_id", locationId);
    formData.append("pos_type", localStorage.getItem("postype"));

    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          action: "get_orientation",
        })
      );
    }

    $(document).on("click", "#reset-classic-shopping-cart", function () {
      if (locationCart.products.length === 0) {
        return;
      }
      $("#emptyCartModel").modal("show");
    });

    $(document).on("click", "#empty-cart-confirm", function () {
      emptyCart();
      $("#cart-modal").modal("hide");
      $("#emptyCartModel").modal("hide");
      $(".modal-backdrop").remove();
      popupAlert();
      return false;
    });

    $(document).on("click", "#reset-shopping-cart", function () {
      emptyCart();
      $("#cart-modal").modal("hide");
      return false;
    });

    $(document).on("submit", "#commentForm", function (e) {
      e.preventDefault();
      const comment = $("#comment-box").val();
      locationCart.comment = comment;
      $("#order-comment-model").modal("hide");
    });

    $(document).on("submit", ".product-search-form", function (e) {
      e.preventDefault();

      const searchFormData = new FormData($(this)[0]);

      const currentPage = parseInt($(this).attr("data-current-page")) || 1;

      const promo_code = $("#classic_promo_code").val();
      const postype = localStorage.getItem("postype") ?? null;
      searchFormData.append("total_products_per_page", totalProducts);
      searchFormData.append("event_id", locationId);
      searchFormData.append("page", currentPage - 1);
      searchFormData.append("product_category_id", "all");
      searchFormData.append("promo_code", promo_code);
      searchFormData.append("location_type", locationType);
      searchFormData.append("pos_type", postype);
      searchProductList(searchFormData, "all");
    });

    function searchProductList(formData, categoryId = "", scrollPosition = 0) {
      formData.append("orientation", screenOrientation);
      $.ajax({
        type: "POST",
        url: "/ajax/events/get_all_products",
        data: formData,
        enctype: "multipart/form-data",
        async: true,
        cache: false,
        processData: false,
        contentType: false,
      })
        .done(function (data) {
          if (data.html !== undefined && data.html !== null) {
            $("#classic-category-product-container").empty();
            $("#classic-category-product-container").html(data.html);
            $("#promo-code-alert-message").fadeOut(5000);
            setTimeout(function () {
              $("#promo-code-alert-message").addClass("d-none");
            }, 5000);

            if (
              scrollPosition !== 0 &&
              scrollPosition !== undefined &&
              scrollPosition !== null
            ) {
              const navContainer = $("#nav-scroll-container");
              navContainer.scrollLeft(scrollPosition);
            }
          }
          adjustProductsHeight();
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }
        });
    }

    $(window).on("orientationchange", function (event) {
      var angle = window.screen.orientation.type.includes("landscape") ? 90 : 0;
      switch (angle) {
        case 0:
        case 180:
          screenOrientation = "PORTRAIT";
          $(
            ".product-container, #suggested-product, #classic-cart-table"
          ).addClass("portrait");
          break;
        case 90:
        case 270:
          screenOrientation = "LANDSCAPE";
          $(
            ".product-container, #suggested-product, #classic-cart-table"
          ).removeClass("portrait");
          break;
      }
    });

    searchProductList(formData);

    // Cart Process
    let locationType = $("#location-products").attr("data-location-type");
    let userId = $("#location-products").attr("data-user-id");
    locationCart = {
      location_id: locationId,
      location_type: locationType,
      table_number: "",
      number_of_products: 0,
      amount: 0,
      tips_percent: 0,
      products: [],
      payment_method: "",
      comment: "",
      name: "",
      phone_number: "",
      pickup_date: "",
      pickup_time: "",
      promo_code: "",
      pick_up_at_the_bar: false,
      order_storage_identifier: "",
      user_id: null,
      customer_id: "",
      pos_type: posType,
      order_discount_percent: 0,
    };

    function getCartInfoFromSession() {
      $.ajax({
        type: "GET",
        url: "/ajax/order/get_cart_from_session",
      })
        .done(function (data) {
          if (
            data.location_id !== undefined &&
            data.location_id !== null &&
            data.location_id == locationId
          ) {
            data.set_payment_failed = true;
            setClassicLocationCart(data);
          }
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }
        });
    }

    if (!($(".clear-shopping-cart").length > 0)) {
      getCartInfoFromSession();
    }

    updateMenuButtons = function () {
      if (locationCart.products.length) {
        for (let i = 0; i < locationCart.products.length; i++) {
          let productId = locationCart.products[i].product_id;
          let input = $("#product-id-" + productId);
          input.val(locationCart.products[i].amount);
          let addToCartButton = input.siblings("button.add-product");
          if (
            input.val() > 0 &&
            !(
              addToCartButton.attr("data-has-product-extra") == 1 &&
              addToCartButton.attr("data-force-product-extra-popup") == 1
            )
          ) {
            if (!input.siblings("span").hasClass("d-none")) {
              input.siblings("span").addClass("d-none");
            }
            if (input.siblings(".remove-product").hasClass("d-none")) {
              input.siblings(".remove-product").removeClass("d-none");
            }
            if (input.hasClass("d-none")) {
              input.removeClass("d-none");
            }
          }
        }
      }
    };

    function areEqual(arr1, arr2) {
      if (arr1.length !== arr2.length) {
        return false;
      }
      for (let i = 0; i < arr1.length; i++) {
        if (JSON.stringify(arr1) !== JSON.stringify(arr2)) {
          return false;
        }
      }
      return true;
    }

    $(document).on("click", "#add-to-cart-button", function () {
      let numberOfProduct = parseInt($("input[name='product-to-order']").val());
      let productId = parseInt(
        $("input[name='product-to-order']").attr("data-product-id")
      );
      let productType = $(this).attr("data-product-type");
      let comment = $("textarea[name='comment']").val();
      let productExtraArray = $("input.product-extra-to-order");
      let productExtraTextArray = $("input.product-extra-text-to-order");
      let productExtras = [];

      productExtraArray.each(function () {
        if (parseInt($(this).val()) > 0) {
          let productExtra = {
            product_extra_id: parseInt($(this).attr("data-product-extra-id")),
            amount: parseInt($(this).val()),
            original_price: parseFloat($(this).attr("data-price-incl-vat")),
          };
          productExtras.push(productExtra);
        }
      });
      productExtraTextArray.each(function () {
        if ($(this).is(":checked")) {
          let productExtra = {
            product_extra_id: parseInt($(this).attr("data-product-extra-id")),
            amount: 1,
            checked: true,
            original_price: 0,
          };
          productExtras.push(productExtra);
        }
      });
      productExtras.sort(function (a, b) {
        return a["product_extra_id"] > b["product_extra_id"]
          ? 1
          : a["product_extra_id"] < b["product_extra_id"]
          ? -1
          : 0;
      });
      let allProducts = locationCart.products;

      let productInCart = false;
      if (productExtras.length > 0) {
        if (allProducts.length > 0) {
          for (let [index, product] of allProducts.entries()) {
            if (product.product_id === productId) {
              if ("product_extra" in product) {
                if (areEqual(product.product_extra, productExtras) === true) {
                  locationCart.products[index].amount =
                    locationCart.products[index].amount + numberOfProduct;
                  locationCart.products[index].comment = comment;
                  productInCart = true;
                  break;
                }
              }
            }
          }
        }
      } else {
        if (allProducts.length > 0) {
          for (let product of allProducts) {
            if (product.product_id === productId) {
              if (!("product_extra" in product)) {
                let productIndex = locationCart.products.findIndex(
                  (obj) => obj.product_id === product.product_id
                );
                if (productIndex !== -1) {
                  locationCart.products[productIndex].amount =
                    locationCart.products[productIndex].amount +
                    numberOfProduct;
                  locationCart.products[productIndex].comment = comment;
                  productInCart = true;
                  break;
                }
              }
            }
          }
        }
      }

      if (productInCart === false) {
        const productPrice =
          parseFloat($("#subtotal").text().replace(",", ".")) / numberOfProduct;
        let product = {
          product_id: productId,
          amount: numberOfProduct,
          comment: comment,
          discount_percent: locationCart.order_discount_percent || 0,
          original_product_price: productPrice,
        };
        if (productExtras.length > 0) {
          product.product_extra = productExtras;
        }
        locationCart.products.push(product);
      }
      let priceInclVat = parseFloat($("#subtotal").text().replace(",", "."));
      locationCart.number_of_products =
        parseInt(locationCart.number_of_products) + numberOfProduct;
      locationCart.amount = parseFloat(locationCart.amount) + priceInclVat;
      updateCartSummary();
      updateCartProducts();
      $("#product-extra-modal").modal("hide");
      if (productType === "suggested") {
        $(`#suggested-product-${productId}`).remove();
        adjustProductsHeight();
        if ($("#suggested-product div").length === 0) {
          $(".suggested-product-list-container").attr("hidden", true);
          $("#remove-suggested-product-container").attr("hidden", true);
        }
      }
    });

    $(document).on("click", ".add-product-with-extra-products", function () {
      let numberOfProduct = parseInt(
        $(this).siblings("input.product-to-order").val()
      );
      let productPrice = parseFloat($(this).attr("data-price-incl-vat"));
      let subTotal = parseFloat($("#subtotal").text().replace(",", "."));
      let sumPriceExtra = subTotal - productPrice * numberOfProduct;
      let sumPriceExtraPerProduct = sumPriceExtra / numberOfProduct;
      numberOfProduct = numberOfProduct + 1;
      subTotal = (
        (productPrice + sumPriceExtraPerProduct) *
        numberOfProduct
      ).toFixed(2);
      $(this).siblings("input.product-to-order").val(numberOfProduct);
      $("#subtotal").text(subTotal.replace(".", ",").replace(",00", ""));
    });

    $(document).on("click", ".remove-product-with-extra-products", function () {
      let numberOfProduct = parseInt(
        $(this).siblings("input.product-to-order").val()
      );
      if (numberOfProduct === 1) {
        return false;
      }
      let productPrice = parseFloat($(this).attr("data-price-incl-vat"));
      let subTotal = parseFloat($("#subtotal").text().replace(",", "."));
      let sumPriceExtra = subTotal - productPrice * numberOfProduct;
      let sumPriceExtraPerProduct = sumPriceExtra / numberOfProduct;
      numberOfProduct = numberOfProduct - 1;
      subTotal = (
        (productPrice + sumPriceExtraPerProduct) *
        numberOfProduct
      ).toFixed(2);
      $(this).siblings("input.product-to-order").val(numberOfProduct);
      $("#subtotal").text(subTotal.replace(".", ",").replace(",00", ""));
    });

    $(document).on("click", ".add-product-extra", function () {
      if (!$(this).siblings("span").hasClass("d-none")) {
        $(this).siblings("span").addClass("d-none");
      }
      if ($(this).siblings(".remove-product-extra").hasClass("d-none")) {
        $(this).siblings(".remove-product-extra").removeClass("d-none");
      }
      if ($(this).siblings("input.product-extra-to-order").hasClass("d-none")) {
        $(this).siblings("input.product-extra-to-order").removeClass("d-none");
      }
      let numberOfProductExtra = parseInt(
        $(this).siblings("input.product-extra-to-order").val()
      );
      let productExtraPrice = parseFloat($(this).attr("data-price-incl-vat"));
      let productId = parseFloat($(this).attr("data-product-id"));
      const cartIndex = $(this).data("cart-index");
      let numberOfProduct = parseInt(
        $("#product-with-extra-products-id-" + productId).val()
      );

      if (cartIndex !== "" && cartIndex >= 0) {
        const productInCart = locationCart.products[cartIndex];
        const productOriginalPrice =
          locationCart.products[cartIndex].original_product_price;
        const updatedProductOriginalPrice =
          parseFloat(productOriginalPrice) + parseFloat(productExtraPrice);

        locationCart.products[cartIndex].original_product_price =
          updatedProductOriginalPrice;

        const discountPercent = parseFloat(
          $("#product-discount-percent-input").attr("data-original-value")
        );

        const totalPriceInput = $("#product-discount-price-input");
        const totalPrice =
          productInCart.amount *
          (updatedProductOriginalPrice * (1 - discountPercent / 100));
        totalPriceInput.val(totalPrice).attr("data-original-value", totalPrice);
      }

      numberOfProductExtra = numberOfProductExtra + 1;
      $(this)
        .siblings("input.product-extra-to-order")
        .val(numberOfProductExtra);
      let subTotal = parseFloat($("#subtotal").text().replace(",", "."));
      subTotal = (subTotal + productExtraPrice * numberOfProduct).toFixed(2);
      $("#subtotal").text(subTotal.replace(".", ",").replace(",00", ""));
    });

    $(document).on("click", ".remove-product-extra", function () {
      let numberOfProductExtra = parseInt(
        $(this).siblings("input.product-extra-to-order").val()
      );
      let productExtraPrice = parseFloat($(this).attr("data-price-incl-vat"));
      let productId = parseFloat($(this).attr("data-product-id"));
      const cartIndex = $(this).data("cart-index");
      let numberOfProduct = parseInt(
        $("#product-with-extra-products-id-" + productId).val()
      );

      if (cartIndex !== "" && cartIndex >= 0) {
        const productInCart = locationCart.products[cartIndex];
        const productOriginalPrice =
          locationCart.products[cartIndex].original_product_price;
        const updatedProductOriginalPrice =
          productOriginalPrice - productExtraPrice;

        locationCart.products[cartIndex].original_product_price =
          updatedProductOriginalPrice;

        const discountPercent = parseFloat(
          $("#product-discount-percent-input").attr("data-original-value")
        );

        const totalPriceInput = $("#product-discount-price-input");
        const totalPrice =
          productInCart.amount *
          (updatedProductOriginalPrice * (1 - discountPercent / 100));
        totalPriceInput.val(totalPrice).attr("data-original-value", totalPrice);
      }

      numberOfProductExtra = numberOfProductExtra - 1;
      $(this)
        .siblings("input.product-extra-to-order")
        .val(numberOfProductExtra);
      let subTotal = parseFloat($("#subtotal").text().replace(",", "."));
      subTotal = (subTotal - productExtraPrice * numberOfProduct).toFixed(2);
      $("#subtotal").text(subTotal.replace(".", ",").replace(",00", ""));
      if (numberOfProductExtra === 0) {
        if ($(this).siblings("span").hasClass("d-none")) {
          $(this).siblings("span").removeClass("d-none");
        }
        if (!$(this).hasClass("d-none")) {
          $(this).addClass("d-none");
        }
        if (
          !$(this).siblings("input.product-extra-to-order").hasClass("d-none")
        ) {
          $(this).siblings("input.product-extra-to-order").addClass("d-none");
        }
      }
    });

    $(document).on(
      "change",
      "div.product-qty-container-wrapper input.product-to-order",
      function () {
        let productId = parseInt($(this).attr("data-product-id"));
        let productIndex = locationCart.products.findIndex(
          (obj) => obj.product_id === productId
        );
        let productAmount = parseInt($(this).val());
        let priceInclVat = parseFloat($(this).attr("data-price-incl-vat"));
        if (productAmount > locationCart.products[productIndex].amount) {
          let productAmountDiff =
            productAmount -
            parseInt(locationCart.products[productIndex].amount);
          locationCart.number_of_products =
            parseInt(locationCart.number_of_products) + productAmountDiff;
          let priceInclVatDiff = productAmountDiff * priceInclVat;
          locationCart.amount =
            parseFloat(locationCart.amount) + priceInclVatDiff;
        }

        if (productAmount < locationCart.products[productIndex].amount) {
          let productAmountDiff =
            parseInt(locationCart.products[productIndex].amount) -
            productAmount;
          locationCart.number_of_products =
            parseInt(locationCart.number_of_products) - productAmountDiff;
          let priceInclVatDiff = productAmountDiff * priceInclVat;
          locationCart.amount =
            parseFloat(locationCart.amount) - priceInclVatDiff;
        }
        locationCart.products[productIndex].amount = productAmount;
        updateCartSummary();
      }
    );

    let numberOfExtraProduct;
    $(document).on(
      "focusin",
      "div#product-extra-modal input.product-to-order",
      function () {
        numberOfExtraProduct = $(this).val();
      }
    );

    $(document).on(
      "change",
      "div#product-extra-modal input.product-to-order",
      function () {
        let numberOfProduct = parseInt($(this).val());
        let productPrice = parseFloat($(this).attr("data-price-incl-vat"));
        let subTotal = parseFloat($("#subtotal").text().replace(",", "."));
        let sumPriceExtra = subTotal - productPrice * numberOfExtraProduct;
        let sumPriceExtraPerProduct = sumPriceExtra / numberOfExtraProduct;
        subTotal = (
          (productPrice + sumPriceExtraPerProduct) *
          numberOfProduct
        ).toFixed(2);
        $("#subtotal").text(subTotal.replace(".", ",").replace(",00", ""));
      }
    );

    let numberOfExtraProductExtra;
    $(document).on(
      "focusin",
      "div#product-extra-modal input.product-extra-to-order",
      function () {
        numberOfExtraProductExtra = $(this).val();
      }
    );

    $(document).on(
      "change",
      "div#product-extra-modal input.product-extra-to-order",
      function () {
        let numberOfProductExtra = parseInt($(this).val());
        let productExtraPrice = parseFloat($(this).attr("data-price-incl-vat"));
        let productId = parseInt($(this).attr("data-product-id"));
        let numberOfProduct = parseInt(
          $("#product-with-extra-products-id-" + productId).val()
        );
        let subTotal = parseFloat($("#subtotal").text().replace(",", "."));
        if (numberOfExtraProductExtra > numberOfProductExtra) {
          let numberOfProductExtraDiff =
            numberOfExtraProductExtra - numberOfProductExtra;
          subTotal = (
            subTotal -
            numberOfProductExtraDiff * productExtraPrice * numberOfProduct
          ).toFixed(2);
        }
        if (numberOfExtraProductExtra < numberOfProductExtra) {
          let numberOfProductExtraDiff =
            numberOfProductExtra - numberOfExtraProductExtra;
          subTotal = (
            subTotal +
            numberOfProductExtraDiff * productExtraPrice * numberOfProduct
          ).toFixed(2);
        }
        $("#subtotal").text(subTotal.replace(".", ",").replace(",00", ""));
      }
    );

    $(document).on("click", ".add-product", function () {
      const productContainer = $(this).closest(".shop-product-container");
      let isForceProductExterBox =
        productContainer.attr("data-force-extra-box") == 1 ? true : false;
      let productType = productContainer.attr("data-product-type");
      let productId = parseInt($(this).attr("data-product-id"));
      let categoryId = productContainer.attr("data-category-id");

      if (
        $(this).attr("data-has-product-extra") == 1 &&
        $(this).attr("data-force-product-extra-popup") == 1 &&
        !isForceProductExterBox
      ) {
        getProductExtraModal(productId);
        if (productType === "suggested") {
          setTimeout(() => {
            $("#add-to-cart-button").attr("data-product-type", productType);
          }, 300);
        }
        return false;
      } else {
        if (!$(this).siblings("span").hasClass("d-none")) {
          $(this).siblings("span").addClass("d-none");
        }
        if ($(this).siblings(".remove-product").hasClass("d-none")) {
          $(this).siblings(".remove-product").removeClass("d-none");
        }
        if ($(this).siblings("input.product-to-order").hasClass("d-none")) {
          $(this).siblings("input.product-to-order").removeClass("d-none");
        }
        let productIndex = locationCart.products.findIndex(
          (obj) => obj.product_id == productId
        );

        if (!locationCart.number_of_products) {
          locationCart.order_discount_percent = 0;
        }

        let productAmount;
        let priceInclVat = parseFloat($(this).attr("data-price-incl-vat"));
        if (productIndex === -1) {
          productAmount = 1;
          let product = {
            product_id: productId,
            original_product_price: priceInclVat,
            amount: productAmount,
            discount_percent: locationCart.order_discount_percent || 0,
            discount_price: 0,
          };
          locationCart.products.push(product);
          locationCart.amount =
            parseFloat(locationCart.amount) +
            priceInclVat * (1 - product.discount_percent / 100);
        } else {
          const priceInclDiscount =
            locationCart.products[productIndex].original_product_price;
          productAmount =
            parseInt(locationCart.products[productIndex].amount) + 1;
          locationCart.products[productIndex].amount = productAmount;
          locationCart.amount =
            parseFloat(locationCart.amount) +
            priceInclDiscount *
              (1 - locationCart.products[productIndex].discount_percent / 100);
        }

        locationCart.amount_without_discount = floor(
          locationCart.products.reduce(
            (total, product) =>
              total +
              parseFloat(
                (product.amount * product.original_product_price).toFixed(2)
              ),
            0
          )
        );
        locationCart.number_of_products =
          parseInt(locationCart.number_of_products) + 1;
        updateCartSummary();
        updateCartProducts();
        let searchString = $("#search_string").val();
        if (searchString) {
          // searchString = "";
          const newFormData = new FormData();
          formData.append("product_category_id", categoryId);
          formData.append("search_string", searchString);
          searchProductList(formData);
        }
        $("input#product-id-" + productId).val(productAmount);
        if (productType == "suggested") {
          productContainer.remove();
          adjustProductsHeight();
          if ($("#suggested-product div").length === 0) {
            $(".suggested-product-list-container").attr("hidden", true);
            $("#remove-suggested-product-container").attr("hidden", true);
          }
        }
      }
    });

    function addProductToCurrentSavedTable() {
      const identifier = locationCart.table_number;
      const productTableButton = $(`button[data-identifier="${identifier}"]`);
      const extraTable = productTableButton.attr("data-extra-table");
      const cart_products = locationCart.products;
      $.ajax({
        type: "POST",
        url: "/ajax/order/save_order_product",
        data: {
          organizer_id: organizerId,
          location_id: locationId,
          table_id: identifier,
          extra_table_id: extraTable,
          cart_products,
        },
      })
        .done(function (data) {
          if (
            data.location_id !== undefined &&
            data.location_id !== null &&
            data.location_id == locationId
          ) {
            $("#order-storage-list-modal").modal("hide");
            // emptyAllCart();
          }

          if (data && data.orderData.length > 0) {
            passDataToWebView(event, "saved_table_print", data.orderData);
          }
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }
        });
    }

    $(document).on("click", ".close-suggested-product", function () {
      $(this)
        .closest(".shop-product-container")
        .fadeOut(200, function () {
          $(this).remove();
        });
      setTimeout(() => {
        if ($("#suggested-product div").length === 0) {
          $(".suggested-product-list-container").attr("hidden", true);
          $("#remove-suggested-product-container").attr("hidden", true);
        }
        adjustProductsHeight();
      }, 300);
    });

    $(document).on(
      "input",
      "#product-discount-price-input, #product-discount-percent-input, #discount-percent-input, .payment-split-input",
      function () {
        if (this.value.length > 1 && this.value.indexOf(0) == 0) {
          $(this).val(Math.abs(this.value));
        }
      }
    );

    $(document).on(
      "keydown",
      "#product-discount-price-input, #discount-percent-input, #product-discount-percent-input, .payment-split-input",
      function (event) {
        if (event.key === "-" || event.key === "+") {
          event.preventDefault();
        }
      }
    );

    $(document).on(
      "input",
      "#product-discount-percent-input, #discount-percent-input",
      function () {
        const value = parseFloat(this.value.replace(",", "."));

        if (this.value.length > 1 && this.value.indexOf(0) == 0) {
          $(this).val(Math.abs(this.value));
        }

        if (value > 100) {
          this.value = 100;
        }
      }
    );

    $(document).on("input", "#product-discount-price-input", function () {
      if (this.value.length > 1 && this.value.indexOf(0) == 0) {
        $(this).val(Math.abs(this.value));
      }
    });

    $(document).on("change", "#product-discount-percent-input", function () {
      const productIndex = $(this).data("cart-index");
      const incomingPercent = parseFloat($(this).val());

      const productPrice =
        locationCart.products[productIndex].original_product_price;
      const productQuantity = locationCart.products[productIndex].amount;
      const originalProductTotalPrice = productPrice * productQuantity;
      const incomingDiscountPrice =
        originalProductTotalPrice * (1 - incomingPercent / 100);

      $("#product-discount-price-input")
        .val(floor(incomingDiscountPrice))
        .attr("data-original-value", incomingDiscountPrice);
      $("#product-discount-percent-input").attr(
        "data-original-value",
        incomingPercent
      );
    });

    $(document).on("change", "#product-discount-price-input", function () {
      const productIndex = $(this).data("cart-index");
      const incomingPrice = parseFloat($(this).val());
      const productPrice =
        locationCart.products[productIndex].original_product_price;
      const productQuantity = locationCart.products[productIndex].amount;
      const discountedProductPrice = incomingPrice / productQuantity;
      const discountPercent = (1 - discountedProductPrice / productPrice) * 100;

      $("#product-discount-percent-input")
        .val(floor(discountPercent))
        .attr("data-original-value", discountPercent);
    });

    $(document).on("keydown", "#discount-percent-input", function (e) {
      if (e.keyCode === 13) {
        e.preventDefault();
        const type = $(this).data("type");
        const orderDiscountpercent =
          parseFloat($("#discount-percent-input").val()) || 0;
        locationCart.order_discount_percent = orderDiscountpercent;

        locationCart.products.map((product, index) => {
          const bestValuePercent =
            orderDiscountpercent > product.discount_percent
              ? orderDiscountpercent
              : product.discount_percent;
          locationCart.products[index].discount_percent =
            Math.floor(bestValuePercent * 100) / 100;
        });

        $("#product-extra-modal").modal("hide");
        $(".modal-backdrop").remove();
        if (
          type !== undefined &&
          type !== null &&
          type === "scroll" &&
          orderStorageIds !== null
        ) {
          getClassicCartModal();
        } else {
          updateCartProducts();
        }
      }
    });

    $(document).on("click", "#add-discount", function (e) {
      e.preventDefault();
      const type = $(this).data("type");
      const orderDiscountpercent =
        parseFloat($("#discount-percent-input").val()) || 0;
      locationCart.order_discount_percent = orderDiscountpercent;

      locationCart.products.map((product, index) => {
        const bestValuePercent =
          orderDiscountpercent > product.discount_percent
            ? orderDiscountpercent
            : product.discount_percent;
        locationCart.products[index].discount_percent = bestValuePercent;
      });

      locationCart.amount_without_discount = floor(
        locationCart.products.reduce(
          (total, product) =>
            total + parseFloat(product.amount * product.original_product_price),
          0
        )
      );

      $("#product-extra-modal").modal("hide");
      $(".modal-backdrop").remove();
      if (
        type !== undefined &&
        type !== null &&
        type === "scroll" &&
        orderStorageIds !== null
      ) {
        getClassicCartModal();
      } else {
        updateCartProducts();
      }
    });

    $(document).on("click", "#show-discount", function () {
      $(this).hide();
      $(".show-discount-percent").removeClass("d-none");
    });

    $(document).on("click", "#update-extra-product-button", function () {
      const type = $(this).data("type");
      let productIndex = parseInt($(this).data("cart-index"));
      let productExtraArray = $("input.product-extra-to-order");
      let productPrice = parseFloat(
        $("#productExtraModalLabel").data("product-price")
      );
      let productExtraTextArray = $("input.product-extra-text-to-order");
      let comment = $("textarea[name='comment']").val();
      let productDiscountPercent =
        $("#product-discount-percent-input").attr("data-original-value") || 0;
      let productDiscountPrice =
        $("#product-discount-price-input").attr("data-original-value") || 0;
      let productExtras = [];
      let originalProductPrice = productPrice;

      productExtraArray.each(function () {
        if (parseInt($(this).val()) > 0) {
          let productExtra = {
            product_extra_id: parseInt($(this).attr("data-product-extra-id")),
            amount: parseInt($(this).val()),
            original_price: parseFloat($(this).attr("data-price-incl-vat")),
          };
          productExtras.push(productExtra);
          originalProductPrice +=
            parseInt($(this).val()) *
            parseFloat($(this).attr("data-price-incl-vat"));
        }
      });
      productExtraTextArray.each(function () {
        if ($(this).is(":checked")) {
          let productExtra = {
            product_extra_id: parseInt($(this).attr("data-product-extra-id")),
            amount: 1,
            checked: true,
            original_price: 0,
          };
          productExtras.push(productExtra);
          originalProductPrice += 0;
        }
      });
      productExtras.sort(function (a, b) {
        return a["product_extra_id"] > b["product_extra_id"]
          ? 1
          : a["product_extra_id"] < b["product_extra_id"]
          ? -1
          : 0;
      });
      locationCart.products[productIndex].original_product_price =
        originalProductPrice;
      locationCart.products[productIndex].product_extra = productExtras;
      locationCart.products[productIndex].comment = comment;
      locationCart.products[productIndex].discount_price = productDiscountPrice;
      locationCart.products[productIndex].discount_percent =
        productDiscountPercent;

      $("#product-extra-modal").modal("hide");
      $(".modal-backdrop").remove();
      if (
        type !== undefined &&
        type !== null &&
        type === "scroll" &&
        orderStorageIds !== null
      ) {
        getClassicCartModal();
      } else {
        updateCartProducts();
      }
    });

    $(document).on("click", "td.product-title", function () {
      let productId = parseInt($(this).attr("data-product-id"));
      let productIndex = parseInt($(this).data("cart-index"));
      let comment = locationCart.products[productIndex].comment;
      const productPrice = $(this)
        .closest("tr")
        .find(".cart-product-amount")
        .data("product-price-incl-vat");

      if (!locationCart.products[productIndex].original_product_price) {
        locationCart.products[productIndex].original_product_price =
          productPrice;
      }

      let discountPrice =
        locationCart.products[productIndex].amount * productPrice;
      let discountPercent =
        locationCart.products[productIndex].discount_percent;
      locationCart.products[productIndex].discount_price = discountPrice;

      if (locationCart.products[productIndex].product_extra) {
        const totalProductExtraPrice = locationCart.products[
          productIndex
        ].product_extra.reduce(
          (total, extraProduct) =>
            total + extraProduct.amount * extraProduct.original_price,
          0
        );
        const individualOriginalProductPrice = parseFloat(
          $(this).data("product-price")
        );
        locationCart.products[productIndex].original_product_price =
          individualOriginalProductPrice + totalProductExtraPrice;
      }

      if ("product_extra" in locationCart.products[productIndex]) {
        let productExtra = locationCart.products[productIndex].product_extra;
        getProductExtraModal(
          productId,
          discountPrice,
          discountPercent,
          1,
          productIndex,
          comment,
          productExtra
        );
      } else {
        getProductExtraModal(
          productId,
          discountPrice,
          discountPercent,
          1,
          productIndex,
          comment
        );
      }
    });

    function getProductExtraModal(
      productId,
      discountPrice = 0,
      discountPercent = 0,
      update = 0,
      productIndex = null,
      comment = "",
      productExtra = [""]
    ) {
      if ($("#product-extra-modal").length) {
        $("#product-extra-modal").remove();
      }

      let data = {
        location_id: locationId,
        product_id: productId,
        location_type: locationCart.location_type,
        product_extra: productExtra,
        update: update,
        comment: comment,
        discount_price: discountPrice,
        discount_percent: discountPercent,
        shop_type: p === "events_self_service" ? "self_service" : "pos",
      };
      if (productIndex !== null) {
        data.cart_index = productIndex;
      }
      if (locationCart.promo_code != "") {
        data.promo_code = locationCart.promo_code;
      }
      $.ajax({
        type: "POST",
        url: "/ajax/order/get_product_extra",
        data: data,
      })
        .done(function (data) {
          if (data.html !== undefined && data.html !== null) {
            $("#event-public-list-organizer").after(data.html);
            $("#product-extra-modal").modal("show");
          }
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }
        });
    }

    updateCartSummary = function () {
      if (!locationCart.amount_without_discount) {
        locationCart.amount_without_discount = 0;
      }
    };

    $(document).on("click", ".remove-product", function () {
      let productId = parseInt($(this).attr("data-product-id"));
      let productIndex = locationCart.products.findIndex(
        (obj) => obj.product_id === productId
      );
      let productAmount =
        parseInt(locationCart.products[productIndex].amount) - 1;
      locationCart.products[productIndex].amount = productAmount;
      let priceInclVat = parseFloat($(this).attr("data-price-incl-vat"));
      locationCart.number_of_products =
        parseInt(locationCart.number_of_products) - 1;
      locationCart.amount = parseFloat(locationCart.amount) - priceInclVat;
      updateCartSummary();
      $("input#product-id-" + productId).val(productAmount);
      if (productAmount === 0) {
        if (!$("input#product-id-" + productId).hasClass("d-none")) {
          $("input#product-id-" + productId).addClass("d-none");
        }
        if ($(this).siblings("span").hasClass("d-none")) {
          $(this).siblings("span").removeClass("d-none");
        }
        $(this).addClass("d-none");
        if (!$("#footer-space-for-cart").hasClass("d-none")) {
          $("#footer-space-for-cart").addClass("d-none");
        }
        if ($("#footer-space-for-cart").hasClass("d-block")) {
          $("#footer-space-for-cart").removeClass("d-block");
        }
      }
    });

    updateCartProducts = function (autoPayWithTerminal) {
      for (let [index, product] of locationCart.products.entries()) {
        if (product.amount === 0) {
          locationCart.products.splice(index, 1);
        }
      }

      locationCart.amount_without_discount = floor(
        locationCart.products.reduce(
          (total, product) =>
            total + parseFloat(product.amount * product.original_product_price),
          0
        )
      );

      let data = {
        location_id: locationId,
        location_type: locationCart.location_type,
        table_number: locationCart.table_number,
        products: locationCart.products,
        tips_percent: locationCart.tips_percent,
        payment_method: locationCart.payment_method,
        comment: locationCart.comment,
        promo_code: locationCart.promo_code,
        amount_without_discount: locationCart.amount_without_discount ?? 0,
        shop_type: p,
        customer_id: locationCart.table_customer_id || "",
        pos_type: locationCart.pos_type,
        table_id: locationCart.table_number || "",
        organizer_id: organizerId,
        orientation: screenOrientation,
      };

      if (locationCart.payment_failed === true) {
        data.payment_failed = locationCart.payment_failed;
      }

      if (locationCart.name != "") {
        data.name = locationCart.name;
      }

      if ($.isNumeric(locationCart.phone_number)) {
        data.phone_number = locationCart.phone_number;
      }

      if (locationCart.pick_up_at_the_bar == true) {
        data.pick_up_at_the_bar = locationCart.pick_up_at_the_bar;
      }
      if (orderStorageIds !== null) {
        data.order_storage_ids = orderStorageIds;
      }

      $.ajax({
        type: "POST",
        url: "/ajax/order/get_cart",
        data: data,
      })
        .done(function (data) {
          if (data.html !== undefined && data.html !== null) {
            if ($("#product-extra-modal").length) {
              $("#product-extra-modal").remove();
            }
            if ($(".modal-backdrop").length) {
              $(".modal-backdrop").remove();
            }

            const totalAmount = parseFloat(
              String(
                $("#cart-amount-with-tips").data("cart-amount-with-tips") || "0"
              ).replace(",", ".")
            );
            locationCart.amount = totalAmount;
            locationCart.amount_without_discount =
              locationCart.amount_without_discount
                ? locationCart.amount_without_discount
                : floor(
                    locationCart.products.reduce(
                      (total, product) =>
                        total +
                        parseFloat(
                          product.amount * product.original_product_price
                        ),
                      0
                    )
                  );

            updateCartSummary();

            if (data.hasSplited) {
              $("#content").append(data.html);
            } else {
              $("#checkout-list").empty();
              $("#checkout-list").html(data.html);
            }
            if (locationCart.table_number && !data.hasSplited) {
              $(".checkout-sub-menu").addClass("d-none");
              setTimeout(() => {
                $("table-number-title");
              }, 300);
            } else {
              $(".checkout-sub-menu").removeClass("d-none");
            }

            $("select[name='customer_number']").select2({
              theme: "bootstrap4",
            });
            if (locationCart.order_discount_percent) {
              $("#discount-percent-input").val(
                locationCart.order_discount_percent
              );
            }

            if (
              data.cart_products !== undefined &&
              data.cart_products !== null
            ) {
              locationCart.products = data.cart_products;
            }
            if (
              locationCart.order_storage_identifier != null &&
              locationCart.order_storage_identifier != ""
            ) {
              locationCart.payment_method = "none";
              $("form#order_register_form").submit();
            } else if (
              autoPayWithTerminal !== undefined &&
              autoPayWithTerminal !== null &&
              autoPayWithTerminal === true
            ) {
              locationCart.payment_method = "payment_terminal";
              $("form#order_register_form").submit();
            }
          }
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }
          cartQuickPaymentTerminalLock = false;
        });

      if (locationCart.name != "") {
        data.name = locationCart.name;
      }

      if ($.isNumeric(locationCart.phone_number)) {
        data.phone_number = locationCart.phone_number;
      }

      if (locationCart.pick_up_at_the_bar == true) {
        data.pick_up_at_the_bar = locationCart.pick_up_at_the_bar;
      }
      if (orderStorageIds !== null) {
        data.order_storage_ids = orderStorageIds;
      }
    };

    $(document).on("click", ".add-tips", function () {
      let tipsPercent = parseInt($(this).attr("data-tips-percent"));
      if (tipsPercent === locationCart.tips_percent) {
        locationCart.tips_percent = 0;
        $(this).removeClass("btn-primary");
        $(this).addClass("btn-light");
      } else {
        locationCart.tips_percent = tipsPercent;
        $(".add-tips").removeClass("btn-primary");
        $(".add-tips").addClass("btn-light");
        $(this).removeClass("btn-light");
        $(this).addClass("btn-primary");
      }
      updateTips();
      if (locationCart.tips_percent == 0) {
        if (!$(".cart-summary-total").hasClass("d-none")) {
          $(".cart-summary-total").addClass("d-none");
        }
        if (!$(".cart-summary-tips").hasClass("d-none")) {
          $(".cart-summary-tips").addClass("d-none");
        }
      } else {
        if ($(".cart-summary-total").hasClass("d-none")) {
          $(".cart-summary-total").removeClass("d-none");
        }
        if ($(".cart-summary-tips").hasClass("d-none")) {
          $(".cart-summary-tips").removeClass("d-none");
        }
      }
    });

    function updateTips() {
      $("#cart-tips-percent").text(locationCart.tips_percent);
      let tips = Math.round(
        (locationCart.amount * locationCart.tips_percent) / 100
      );
      $("#cart-tips").text(tips.toFixed(2).replace(".", ","));
      let amountWithTips = (locationCart.amount + tips).toFixed(2);
      $("#cart-amount-with-tips").text(amountWithTips.replace(".", ","));
      $("#order-button-amount").text(amountWithTips.replace(".", ","));
      $("#tipping").val(
        ((locationCart.amount * locationCart.tips_percent) / 100)
          .toFixed(2)
          .replace(".", ",")
      );
    }

    $(document).on("click", ".cart-add-product", function () {
      let productId = $(this).data("product-id");
      let productIndex = parseInt($(this).data("cart-index"));
      let productAmount = parseInt(locationCart.products[productIndex].amount);
      let cartAmount = parseInt(locationCart.number_of_products);
      productAmount += 1;
      cartAmount += 1;
      locationCart.products[productIndex].amount = productAmount;
      locationCart.number_of_products = cartAmount;
      let productPrice = parseFloat(
        $(this)
          .siblings(".cart-product-amount")
          .data("product-price-incl-vat") || "0"
      );
      let productTotalPrice = (productPrice * productAmount).toFixed(2);
      $(this)
        .closest("td")
        .find(".product-total-price")
        .text(productTotalPrice.replace(".", ","));
      $(this).siblings(".cart-product-amount").text(productAmount);
      $("input#product-id-" + productId).val(productAmount);
      $("input#product-cart-index-" + productIndex).val(productAmount);
      addPriceToCart(productPrice);
      locationCart.amount_without_discount = floor(
        locationCart.products.reduce(
          (total, product) =>
            total +
            parseFloat(
              (product.amount * product.original_product_price).toFixed(2)
            ),
          0
        )
      );
      updateCartSummary();
      updateCartProducts();
    });

    $(document).on("click", ".cart-remove-product", function () {
      let productId = $(this).data("product-id");
      let productIndex = parseInt($(this).data("cart-index"));
      let productAmount = parseInt(locationCart.products[productIndex].amount);
      let cartAmount = parseInt(locationCart.number_of_products);
      productAmount -= 1;
      cartAmount -= 1;
      locationCart.products[productIndex].amount = productAmount;
      locationCart.number_of_products = cartAmount;
      let productPrice = parseFloat(
        $(this)
          .siblings(".cart-product-amount")
          .data("product-price-incl-vat") || "0"
      );
      subtractPriceToCart(productPrice);
      $("input#product-id-" + productId).val(productAmount);
      $("input#product-cart-index-" + productIndex).val(productAmount);

      if (productAmount === 0) {
        $(this).closest("tr").remove();
        if (!$("input#product-id-" + productId).hasClass("d-none")) {
          $("input#product-id-" + productId).addClass("d-none");
        }
        if (
          $("input#product-id-" + productId)
            .siblings("span")
            .hasClass("d-none")
        ) {
          $("input#product-id-" + productId)
            .siblings("span")
            .removeClass("d-none");
        }
        $("input#product-id-" + productId)
          .siblings("button.remove-product")
          .addClass("d-none");
        if (locationCart.number_of_products == 0) {
          $(".modal-backdrop").remove();
        }
        locationCart.products[productIndex].discount_percent = 0;
      } else {
        locationCart.products[productIndex].amount = productAmount;
        let productTotalPrice = (productPrice * productAmount).toFixed(2);
        $(this)
          .closest("td")
          .find(".product-total-price")
          .text(productTotalPrice.replace(".", ","));
        $(this).siblings(".cart-product-amount").text(productAmount);
      }

      locationCart.amount_without_discount = floor(
        locationCart.products.reduce(
          (total, product) =>
            total +
            parseFloat(
              (product.amount * product.original_product_price).toFixed(2)
            ),
          0
        )
      );
      updateCartSummary();
      updateCartProducts();
      if (locationCart.products.length === 0) {
        emptyCart();
        $("#checkout-list").empty();
      }
    });

    $(document).on("change", "#table_number", function () {
      let tableNumber = $(this).val();
      locationCart.table_number = tableNumber;
    });

    $(document).on("change", "#name", function () {
      let name = $(this).val();
      locationCart.name = name;
    });

    $(document).on("change", "#phone_number", function () {
      let phoneNumber = $(this).val();
      locationCart.phone_number = phoneNumber;
    });

    $(document).on("change", "#pickup_date", function () {
      let pickupDate = $(this).val();
      locationCart.pickup_date = pickupDate;
    });

    $(document).on("change", "#pickup_time", function () {
      let pickupTime = $(this).val();
      locationCart.pickup_time = pickupTime;
    });

    function subtractPriceToCart(productPrice) {
      locationCart.amount = locationCart.amount - productPrice;
    }

    function addPriceToCart(productPrice) {
      locationCart.amount = locationCart.amount + productPrice;
    }

    $(document).on("click", "#cart-comment-button", function () {
      if ($("#cart-comment").hasClass("d-none")) {
        $("#cart-comment").removeClass("d-none");
      } else {
        $("#cart-comment").addClass("d-none");
      }
      return false;
    });

    $(document).on("click", "#product-extra-comment-button", function () {
      if ($("#product-extra-comment").hasClass("d-none")) {
        $("#product-extra-comment").removeClass("d-none");
      }
      if ($("#product-extra-comment-label").hasClass("d-none")) {
        $("#product-extra-comment-label").removeClass("d-none");
      }
      $(this).remove();
    });

    $(document).on("change", "#cart-comment", function () {
      locationCart.comment = $("#cart-comment").val();
    });

    $(document).on("click", "#term-button", function () {
      return false;
    });

    $(document).on("click", ".get-products", function () {
      let btn = $(this);
      let locationId = $(this).attr("data-location-id");
      let locationType = $(this).attr("data-location-type");

      if (locationCart.number_of_products > 0) {
        changeLocationType(locationId, locationType, locationCart).done(
          function (data) {
            if (data.are_products_available === false) {
              let confirmMessage = btn.attr(
                "data-location-type-confirm-message"
              );
              if (!confirm(confirmMessage)) {
                $(
                  "input[name='inlineRadioOptions'][data-location-type='" +
                    locationType +
                    "']"
                ).prop("checked", false);
                $(
                  "input[name='inlineRadioOptions'][data-location-type='" +
                    locationCart.location_type +
                    "']"
                ).prop("checked", true);
                return false;
              }
              emptyCart();
            } else {
              locationCart.amount = data.cart_total_price;
            }
            locationCart.location_type = locationType;
            updateCartSummary();
            getProducts(locationId, locationType, locationCart.products);
          }
        );
      } else {
        locationCart.location_type = locationType;
        getProducts(locationId, locationType);
      }
    });

    $(document).on("submit", "#classic-pos-promocode", function (e) {
      e.preventDefault();
      activatePromoCode();
    });

    function activatePromoCode() {
      let promoCode = $("#classic_promo_code").val();
      const searchString = $("#search_string").val();
      let activeCategoryId = $("#nav-scroll-container .nav-link.active").data(
        "category-id"
      );
      if (promoCode == "") {
        return false;
      }

      let promoCodeIsValid = false;
      checkPromoCode(locationId, promoCode, function (promoCodeIsValidBool) {
        promoCodeIsValid = promoCodeIsValidBool;
      });

      setTimeout(function () {
        if (!promoCodeIsValid) {
          if ($("#promo-code-alert-message").hasClass("alert-success")) {
            $("#promo-code-alert-message").removeClass("alert-success");
          }
          if (!$("#promo-code-alert-message").hasClass("alert-danger")) {
            $("#promo-code-alert-message").addClass("alert-danger");
          }
          if ($("#promo-code-alert-message").hasClass("d-none")) {
            $("#promo-code-alert-message").removeClass("d-none");
          }
          if ($("#promo-code-alert-message").css("display") == "none") {
            $("#promo-code-alert-message").css("display", "");
          }
          $("#promo-code-alert-message").text(
            $("#promo-code-button").attr("data-promo-code-error-message")
          );
          $("#promo-code-alert-message").fadeOut(5000);
          setTimeout(function () {
            $("#promo-code-alert-message").addClass("d-none");
          }, 5000);
          return false;
        } else {
          if ($("#promo-code-alert-message").hasClass("alert-danger")) {
            $("#promo-code-alert-message").removeClass("alert-danger");
          }
          if (!$("#promo-code-alert-message").hasClass("alert-success")) {
            $("#promo-code-alert-message").addClass("alert-success");
          }
          if ($("#promo-code-alert-message").hasClass("d-none")) {
            $("#promo-code-alert-message").removeClass("d-none");
          }
          if ($("#promo-code-alert-message").css("display") == "none") {
            $("#promo-code-alert-message").css("display", "");
          }
          $("#promo-code-alert-message").text(
            $("#promo-code-button").attr("data-promo-code-success-message")
          );
          locationCart.promo_code = promoCode;
          let promocodeFormData = new FormData();
          promocodeFormData.append("total_products_per_page", totalProducts);
          promocodeFormData.append("event_id", locationId);
          promocodeFormData.append("product_category_id", activeCategoryId);
          promocodeFormData.append("promo_code", promoCode);
          promocodeFormData.append("location_type", locationType);
          promocodeFormData.append("search_string", searchString);
          formData.append("pos_type", posType);
          searchProductList(promocodeFormData, activeCategoryId);
        }
      }, 1000);
    }

    function checkPromoCode(locationId, promoCode, promoCodeIsValid) {
      $.ajax({
        type: "POST",
        url: "/ajax/event/check_promo_code",
        data: {
          location_id: locationId,
          promo_code: promoCode,
        },
      })
        .done(function (data) {
          if (
            data.promo_code_is_valid !== undefined &&
            data.promo_code_is_valid !== null &&
            data.promo_code_is_valid == true
          ) {
            promoCodeIsValid(true);
          } else {
            promoCodeIsValid(false);
          }
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }
        });
    }

    function changeLocationType(locationId, locationType, locationCart) {
      return $.ajax({
        type: "POST",
        url: "/ajax/event/change_location_type",
        data: {
          location_id: locationId,
          location_type: locationType,
          products: locationCart.products,
        },
      })

        .done(function (data) {
          return {
            are_products_available: data.are_products_available,
            cart_total_price: data.cart_total_price,
          };
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }
        });
    }

    function getProducts(
      locationId,
      locationType,
      products = null,
      promoCode = null,
      shop_type = null
    ) {
      $.ajax({
        type: "POST",
        url: "/ajax/event/get_products",
        data: {
          location_id: locationId,
          location_type: locationType,
          products: products,
          promo_code: promoCode,
          shop_type,
        },
      })
        .done(function (data) {
          if (data.html !== undefined && data.html !== null) {
            $("#location-products").remove();
            if ($("#location-type-buttons").length) {
              $("#location-type-buttons").after(data.html);
            } else if ($("#location-promo-code-form-wrapper").length) {
              $("#location-promo-code-form-wrapper").after(data.html);
            } else {
              $("#location").after(data.html);
            }
          }
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }
        });
    }

    function emptyCart() {
      locationCart.order_storage_identifier = "";
      locationCart.order_storage_ids = [];
      locationCart.number_of_products = 0;
      locationCart.amount = 0;
      locationCart.products = [];
      locationCart.order_discount_percent = 0;
      locationCart.amount_without_discount = 0;
      locationCart.checkout_type = "";
      locationCart.table_customer_id = "";
      locationCart.table_number = "";
      orderStorageIds = null;
      updateCartSummary();
      updateCartProducts();
    }

    emptyAllCart = function () {
      emptyCart();
    };

    if ($(".clear-shopping-cart").length) {
      emptyCart();
    }

    $(document).on("click", ".order-button", function (e) {
      locationCart.order_storage_identifier = "";
      const paymentMethod = $(this).attr("data-payment-method");
      locationCart.payment_method = paymentMethod;
      if (paymentMethod === "payment_terminal") {
        locationCart.terminal_id = $(this).attr("data-terminal-id");
      }
    });

    $(document).on("click", ".close-terminals", function () {
      $("#terminal-list-model").modal("hide");
      return false;
    });

    let order_ajax_lock = false;

    $(document).on("submit", "#order_register_form", function (e) {
      e.preventDefault();

      if (locationCart.products.length === 0) {
        return;
      }

      if (locationCart.payment_method === "payment_split") {
        getPaymentSplitModal(locationCart);
        return;
      }

      $("div.loader").remove();
      $(".order-button").attr("disabled", true);

      if (!order_ajax_lock) {
        $(this).append('<div class="loader"></div>');

        order_ajax_lock = true;

        if ($("#pick-up-at-the-bar").length) {
          locationCart.pick_up_at_the_bar = true;
        }

        let newOrderData = {
          location_id: locationId,
          location_type: locationCart.location_type,
          table_number: locationCart.table_number,
          products: locationCart.products,
          tips_percent: locationCart.tips_percent,
          payment_method: locationCart.payment_method,
          terminal_id: locationCart.terminal_id,
          comment: locationCart.comment,
          name: locationCart.name,
          phone_number: locationCart.phone_number,
          pickup_date: locationCart.pickup_date,
          pickup_time: locationCart.pickup_time,
          promo_code: locationCart.promo_code,
          custom_pickup_time: $("#custom_pickup_time").val(),
          pick_up_at_the_bar: locationCart.pick_up_at_the_bar,
          order_storage_identifier: locationCart.order_storage_identifier,
          userId,
          customer_number: locationCart.customer_id,
          table_customer_id: locationCart.table_customer_id ?? "",
          shop_type,
        };

        if (orderStorageIds !== null) {
          newOrderData.order_storage_ids = orderStorageIds;
        }

        $.ajax({
          type: "POST",
          url: "/ajax/order/new",
          data: newOrderData,
          timeout: 120000,
        })
          .done(function (data) {
            if (
              data.orderProducts &&
              data.order &&
              data.organizer &&
              (locationCart.payment_method === "payment_preprint" ||
                locationCart.payment_method === "payment_cash")
            ) {
              passDataToWebView(
                e,
                "print_order",
                data.orderProducts,
                data.printerIP,
                data.printerType,
                data.organizer,
                data.order
              );
              if (!locationCart.table_number && data.production_print_data) {
                passDataToWebView(
                  event,
                  "print_poll_order",
                  data.production_print_data
                );
              }
            }
            $("div.loader").remove();
            $(".order-button").each(function () {
              if (
                $(this).is("[data-toggle]") &&
                ($(this).data("payment-method") === "nets_card" ||
                  $(this).data("payment-method") === "nets_vipps")
              ) {
                $(this).prop("disabled", true);
              } else {
                $(this).prop("disabled", false);
              }
            });
            if (
              data.payment_redirect_url !== undefined &&
              data.payment_redirect_url !== null
            ) {
              document.location.href = data.payment_redirect_url;
            }

            if (
              data.confirmation_code !== undefined &&
              data.confirmation_code !== null
            ) {
              const orderHtml =
                data.order_html !== undefined && data.order_html !== null
                  ? data.order_html
                  : "";
              $(".modal-backdrop").remove();
              if (
                $("#content").hasClass("is-employee") &&
                (locationCart.payment_method == "payment_terminal" ||
                  locationCart.payment_method == "payment_cash" ||
                  locationCart.payment_method == "payment_preprint" ||
                  locationCart.payment_method == "payment_method_coupon" ||
                  locationCart.payment_method == "none")
              ) {
                emptyCart();
                $("#cart-modal").modal("hide");
                $(".modal-backdrop").remove();
                $(".confirmation-toast").remove();
                $("#content").append(
                  '<div class="position-fixed bottom-0 right-0 p-3 confirmation-toast" style="z-index: 1001; right: 0; bottom: 0;">' +
                    '<div role="alert" aria-live="assertive" aria-atomic="true" class="toast" data-autohide="true" data-delay="2000">' +
                    '  <div class="toast-header">' +
                    '    <strong class="mr-auto">Ordre registrert</strong>' +
                    "    <small></small>" +
                    '    <button type="button" class="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close">' +
                    '      <span aria-hidden="true">&times;</span>' +
                    "    </button>" +
                    "  </div>" +
                    '  <div class="toast-body">' +
                    orderHtml +
                    "  </div>" +
                    "</div>" +
                    "</div>"
                );
                $(".toast").toast("show");
                setTimeout(function () {
                  order_ajax_lock = false;
                  cartQuickPaymentTerminalLock = false;
                  if (data.pin_required) {
                    document.location.href = `/user/organizer_login?redirect_url=${encodeURIComponent(
                      document.location.pathname
                    )}`;
                  } else if (data.login_reqiured) {
                    document.location.href = "/logout";
                  }
                }, 3000);
                emptyCartOnModalClose = true;

                localStorage.removeItem("saved_table_id");
                $(".order-button").each(function () {
                  if (
                    $(this).is("[data-toggle]") &&
                    ($(this).data("payment-method") === "nets_card" ||
                      $(this).data("payment-method") === "nets_vipps")
                  ) {
                    $(this).prop("disabled", true);
                  } else {
                    $(this).prop("disabled", false);
                  }
                });
              } else {
                localStorage.removeItem("saved_table_id");
                document.location.href =
                  "/order/confirmation/" + data.confirmation_code;
              }
            }
          })
          .fail(function (data) {
            $("div.loader").remove();
            $(".order-button").each(function () {
              if (
                $(this).is("[data-toggle]") &&
                ($(this).data("payment-method") === "nets_card" ||
                  $(this).data("payment-method") === "nets_vipps")
              ) {
                $(this).prop("disabled", true);
              } else {
                $(this).prop("disabled", false);
              }
            });
            errorPopupAlert("Payment fail!");
            if (
              data.responseJSON !== undefined &&
              data.responseJSON.error_message !== undefined &&
              data.responseJSON.error_message !== null
            ) {
              if (!$("#order-button-wrapper").is(":visible")) {
                alert(data.responseJSON.error_message);
              } else {
                blinkButton(
                  $(`.terminal-button-${locationCart.terminal_id}`),
                  "red"
                );
                $(".loader").attr("hidden", true);
                prependAlert(
                  "#order-button-wrapper, #terminal-modal-body",
                  data.responseJSON.error_message,
                  "danger",
                  null,
                  "#order_register_form"
                );
              }
            }
            order_ajax_lock = false;
            cartQuickPaymentTerminalLock = false;
          })
          .always(function () {
            locationCart.order_storage_identifier = "";
            if (
              !(
                $("#content").hasClass("is-employee") &&
                (locationCart.payment_method == "payment_terminal" ||
                  locationCart.payment_method == "payment_cash" ||
                  locationCart.payment_method == "payment_method_coupon")
              )
            ) {
              order_ajax_lock = false;
            }
          });
      }
      return false;
    });

    $(document).on("click", ".shop-employee-mode .shop-product", function () {
      const productContainer = $(this).closest(".shop-product-container");

      $(".add-product", productContainer).click();
      const shopProduct = $(this);
      shopProduct.focus();
      if (this._focusTimeout) {
        clearTimeout(this._focusTimeout);
      }

      this._focusTimeout = setTimeout(() => {
        shopProduct.blur();
        delete this._focusTimeout;
      }, 100);
    });

    let cartQuickPaymentTerminalLock = false;
    $(document).on(
      "click",
      ".cart-quick-payment-terminal .order-button",
      function () {
        if (!cartQuickPaymentTerminalLock) {
          cartQuickPaymentTerminalLock = true;
          updateCartProducts(true);
        }
      }
    );

    let isNameRequired = false;
    let isPhoneNumberRequired = false;
    $(document).on("click", "#pick-up-at-the-bar-button", function () {
      if (isNameRequired === false && $("#name").prop("required")) {
        isNameRequired = true;
      }
      if (
        isPhoneNumberRequired === false &&
        $("#phone_number").prop("required")
      ) {
        isPhoneNumberRequired = true;
      }
      if ($(this).hasClass("btn-primary")) {
        locationCart.pick_up_at_the_bar = true;
        $(this).removeClass("btn-primary");
        $(this).addClass("btn-light");
        $("#table_number").attr("required", false);
        $("#name").attr("required", false);
        $("#phone_number").attr("required", false);
        $("#table_number").val("");
        $("#name").val("");
        $("#phone_number").val("");
        if (!$(".get-delivered-to-table").hasClass("d-none")) {
          $(".get-delivered-to-table").addClass("d-none");
        }
        if ($(this).closest("div").hasClass("col-4")) {
          $(this).closest("div").removeClass("col-4");
          $(this).closest("div").addClass("col-8");
        }
      } else {
        locationCart.pick_up_at_the_bar = false;
        $(this).removeClass("btn-light");
        $(this).addClass("btn-primary");
        if ($(".get-delivered-to-table").hasClass("d-none")) {
          $(".get-delivered-to-table").removeClass("d-none");
        }
        if ($(this).closest("div").hasClass("col-8")) {
          $(this).closest("div").removeClass("col-8");
          $(this).closest("div").addClass("col-4");
        }
        $("#table_number").attr("required", true);
        if (isNameRequired) {
          $("#name").attr("required", true);
        }
        if (isNameRequired) {
          $("#phone_number").attr("required", true);
        }
      }
      return false;
    });

    $(document).on("change", "#customer_number", function () {
      const customer_id = parseInt(this.value);
      const type = $(this).data("type");
      locationCart.customer_id = customer_id;

      $(".modal-backdrop").remove();
      locationCart.products = locationCart.products.map((product) => {
        return {
          ...product,
          discount_percent: 0,
        };
      });
      if (
        type !== undefined &&
        type !== null &&
        type === "scroll" &&
        orderStorageIds !== null
      ) {
        getClassicCartModal();
      } else {
        updateCartProducts();
      }
    });

    $(document).on("change", "#customer_select", function () {
      const customer_id = parseInt(this.value);
      locationCart.customer_id = customer_id;

      locationCart.products = locationCart.products.map((product) => {
        return {
          ...product,
          discount_percent: 0,
        };
      });

      $(".modal-backdrop").remove();
      getClassicCartModal();
    });

    $(document).on("hide.bs.modal", "#cart-modal", function (event) {
      if (orderStorageIds !== null || locationCart.table_customer_id) {
        emptyCart();
      }
    });

    $(document).on("hide.bs.modal", "#product-extra-modal", function (event) {
      $("body").removeClass("modal-open");
    });

    $(document).on("hide.bs.modal", "#payment-split-modal", function (event) {
      setTimeout(() => {
        $("#payment-split-modal").remove();
      }, 200);
    });

    getClassicCartModal = function (autoPayWithTerminal) {
      for (let [index, product] of locationCart.products.entries()) {
        if (product.amount === 0) {
          locationCart.products.splice(index, 1);
        }
      }

      locationCart.amount_without_discount = floor(
        locationCart.products.reduce(
          (total, product) =>
            total +
            parseFloat(
              (product.amount * product.original_product_price).toFixed(2)
            ),
          0
        )
      );

      let data = {
        location_id: locationId,
        location_type: locationCart.location_type,
        table_number: locationCart.table_number,
        products: locationCart.products,
        tips_percent: locationCart.tips_percent,
        payment_method: locationCart.payment_method,
        comment: locationCart.comment,
        promo_code: locationCart.promo_code,
        amount_without_discount: locationCart.amount_without_discount ?? 0,
        shop_type: p,
        customer_id: locationCart.table_customer_id || "",
        pos_type: "classic",
        checkout_type: locationCart.checkout_type ?? null,
        table_id: locationCart.table_number || "",
        organizer_id: organizerId,
        order_storage_ids: orderStorageIds,
        orientation: screenOrientation,
      };

      if (locationCart.payment_failed === true) {
        data.payment_failed = locationCart.payment_failed;
      }

      if (locationCart.name != "") {
        data.name = locationCart.name;
      }

      if ($.isNumeric(locationCart.phone_number)) {
        data.phone_number = locationCart.phone_number;
      }

      if (locationCart.pick_up_at_the_bar == true) {
        data.pick_up_at_the_bar = locationCart.pick_up_at_the_bar;
      }
      if (orderStorageIds !== null) {
        orderStorageIds = data.order_storage_ids;
      }

      $.ajax({
        type: "POST",
        url: "/ajax/order/get_cart",
        data: data,
      })
        .done(function (data) {
          if (data.html !== undefined && data.html !== null) {
            if ($("#product-extra-modal").length) {
              $("#product-extra-modal").remove();
            }
            if ($("#cart-modal").length) {
              $("#cart-modal").remove();
            }

            if (data.hasSplited) {
              $("#content").append(data.html);
            } else {
              $("#checkout-list").empty();
              $("#checkout-list").html(data.html);
            }

            if (locationCart.table_number && !data.hasSplited) {
              $(".checkout-sub-menu").addClass("d-none");
            } else {
              $(".checkout-sub-menu").removeClass("d-none");
            }

            locationCart.products = data.cart_products;

            const totalAmount = parseFloat(
              String(
                $("#cart-amount-with-tips").data("cart-amount-with-tips") || "0"
              ).replace(",", ".")
            );
            locationCart.amount = totalAmount;
            locationCart.amount_without_discount = floor(
              locationCart.products.reduce(
                (total, product) =>
                  total +
                  parseFloat(
                    (product.amount * product.original_product_price).toFixed(2)
                  ),
                0
              )
            );

            updateCartSummary();

            if (locationCart.order_discount_percent) {
              $("#discount-percent-input").val(
                locationCart.order_discount_percent
              );
            }

            if (
              locationCart.order_storage_identifier != null &&
              locationCart.order_storage_identifier != ""
            ) {
              locationCart.payment_method = "none";
              $("form#order_register_form").submit();
            } else if (
              autoPayWithTerminal !== undefined &&
              autoPayWithTerminal !== null &&
              autoPayWithTerminal === true
            ) {
              locationCart.payment_method = "payment_terminal";
              $("form#order_register_form").submit();
            } else {
              if ($("#split-payment-cart-modal").length) {
                $("#split-payment-cart-modal").remove();
                $("#content").append(data.html);
                $("#split-payment-cart-modal").modal("show");
              } else {
                $("#cart-modal").modal("show");
              }
            }
          }
          $("select[name='customer_number']").each(function () {
            $(this).select2({
              theme: "bootstrap4",
            });
          });

          const allpersonlist = $(".select-person");
          if (allpersonlist.length > 0) {
            const lastPerson = allpersonlist[allpersonlist.length - 1];
            if (lastPerson) {
              lastPerson.click();
            }
          }
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }
          cartQuickPaymentTerminalLock = false;
        });
    };

    if (
      !error_message &&
      !splitOrderId &&
      localStorage.getItem("location-cart")
    ) {
      localStorage.removeItem("location-cart");
    }

    if (error_message && localStorage.getItem("location-cart")) {
      locationCart = JSON.parse(localStorage.getItem("location-cart"));
    }

    if (splitOrderId && localStorage.getItem("location-cart")) {
      locationCart = JSON.parse(localStorage.getItem("location-cart"));
      locationCart.order_id = splitOrderId;
    }

    updateCartProducts();

    if (splitOrderId) {
      getPaymentSplitModal(locationCart);
    }

    var modalTimeout = null;
    $(document).on("click", "#recorder", function (e) {
      $(this).toggleClass("active");
      if ($(this).hasClass("active")) {
        startRecording();
        startOrResetFunction(() => {
          $("#product-suggestion-modal").modal({
            backdrop: "static",
            keyboard: false,
          });

          modalTimeout = setTimeout(() => {
            $("#cancel-suggestion").click();
          }, 5000);
        }, 180000);
      } else {
        stopRecording();
        clearInactivityTimeout();
      }
    });

    $(document).on("click", "#continue-suggestion", function () {
      $("#product-suggestion-modal").modal("hide");
      clearTimeout(modalTimeout);
      startOrResetFunction(() => {
        $("#product-suggestion-modal").modal("show");
        modalTimeout = setTimeout(() => {
          $("#product-suggestion-modal").modal("hide");
        }, 5000);
      }, 180000);
    });

    $(document).on("click", "#cancel-suggestion", function () {
      $("#product-suggestion-modal").modal("hide");
      stopRecording();
      clearInactivityTimeout();
      clearTimeout(modalTimeout);
    });

    $(document).on("click", ".order-payment-split", function (e) {
      e.preventDefault();
      const siblingInput = $(this).siblings(".payment-split-input");

      if (!siblingInput.val() || parseFloat(siblingInput.val()) <= 0) {
        return;
      }

      const orderId = $("#payment-split-modal").attr("data-order-id");
      const button = $(this);
      const buttonText = button.text();
      $("div.loader").remove();
      $(".order-button").attr("disabled", true);
      $(".order-payment-split").attr("disabled", true);

      locationCart.order_storage_identifier = "";
      const paymentMethod = $(this).attr("data-payment-method");
      locationCart.payment_split_method = paymentMethod;
      if (paymentMethod === "payment_terminal") {
        locationCart.terminal_id = $(this).attr("data-terminal-id");
      }

      if (
        locationCart.payment_split_method == "nets_card" ||
        locationCart.payment_split_method == "nets_vipps"
      ) {
        localStorage.setItem("location-cart", JSON.stringify(locationCart));
      }

      if (!order_ajax_lock) {
        button.empty();
        button.append('<div class="loader"></div>');

        order_ajax_lock = true;

        if (orderId) {
          const payload = {
            order_id: orderId,
            amount: siblingInput.val(),
            payment_method: locationCart.payment_split_method,
            terminal_id: locationCart.terminal_id,
            shop_type,
          };

          $.ajax({
            type: "POST",
            url: "/api/v1/private/order/payment-split",
            data: payload,
          })
            .done(function (data) {
              $("div.loader").remove();
              $(".order-button").attr("disabled", false);
              $(".order-payment-split").attr("disabled", false);
              button.attr("disabled", true);
              button.removeClass("btn-primary");
              button.addClass("btn-success");
              button.css("opacity", "1");
              button.append('<i class="material-icons p-0">check_circle</i>');
              siblingInput.attr("disabled", true);

              if (data.order_id !== null && data.order_id !== undefined) {
                $("#payment-split-modal").attr("data-order-id", data.order_id);
              }

              if (
                data.payment_redirect_url !== undefined &&
                data.payment_redirect_url !== null
              ) {
                document.location.href = data.payment_redirect_url;
              }

              const paymentSplitInputs = $(".payment-split-input").toArray();

              const totalPaid = paymentSplitInputs.reduce((acc, el) => {
                return acc + (parseFloat($(el).val()) || 0);
              }, 0);

              let totalAmount = parseFloat(
                $(".payment-split-input").first().attr("data-max-allowed")
              );

              if (totalPaid > 0) {
                let remainingValue =
                  (totalAmount * 100 - totalPaid * 100) / 100;
                $("#total-amount").text(
                  `Hvile : ${formattedPriceToShow(remainingValue)}`
                );
              }
              const orderHtml =
                data.order_html !== undefined && data.order_html !== null
                  ? data.order_html
                  : "";
              if ($("#content").hasClass("is-employee") && orderHtml !== "") {
                $("#payment-split-modal").modal("hide");

                $("#cart-modal").modal("hide");
                $(".modal-backdrop").remove();
                $(".confirmation-toast").remove();
                $("#content").append(
                  '<div class="position-fixed bottom-0 right-0 p-3 confirmation-toast" style="z-index: 1001; right: 0; bottom: 0;">' +
                    '<div role="alert" aria-live="assertive" aria-atomic="true" class="toast" data-autohide="true" data-delay="2000">' +
                    '  <div class="toast-header">' +
                    '    <strong class="mr-auto">Ordre registrert</strong>' +
                    "    <small></small>" +
                    '    <button type="button" class="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close">' +
                    '      <span aria-hidden="true">&times;</span>' +
                    "    </button>" +
                    "  </div>" +
                    '  <div class="toast-body">' +
                    orderHtml +
                    "  </div>" +
                    "</div>" +
                    "</div>"
                );
                $(".modal-backdrop").remove();
                $(".toast").toast("show");
                removeQueryParams("order");
                splitOrderId = null;
                locationCart.order_id = null;
                localStorage.removeItem("location-cart");

                passDataToWebView(
                  e,
                  "print_order",
                  data.orderProducts,
                  data.printerIP,
                  data.printerType,
                  data.organizer,
                  data.order
                );

                if (!locationCart.table_number && data.production_print_data) {
                  passDataToWebView(
                    event,
                    "print_poll_order",
                    data.production_print_data
                  );
                }
                emptyCart();
              }
              order_ajax_lock = false;
            })
            .fail(function (data) {
              $("div.loader").remove();
              $(".order-button").attr("disabled", false);
              $(".order-payment-split").attr("disabled", false);
              button.text(buttonText);
              errorPopupAlert("Payment fail!");
              if (
                data.responseJSON !== undefined &&
                data.responseJSON.error_message !== undefined &&
                data.responseJSON.error_message !== null
              ) {
                if (!$("#order-button-wrapper").is(":visible")) {
                  alert(data.responseJSON.error_message);
                } else {
                }
              }
              order_ajax_lock = false;
              cartQuickPaymentTerminalLock = false;
            });

          return;
        }

        if ($("#pick-up-at-the-bar").length) {
          locationCart.pick_up_at_the_bar = true;
        }

        let newOrderData = {
          location_id: locationId,
          location_type: locationCart.location_type,
          table_number: locationCart.table_number,
          products: locationCart.products,
          tips_percent: locationCart.tips_percent,
          payment_method: locationCart.payment_method,
          terminal_id: locationCart.terminal_id,
          comment: locationCart.comment,
          name: locationCart.name,
          phone_number: locationCart.phone_number,
          pickup_date: locationCart.pickup_date,
          pickup_time: locationCart.pickup_time,
          promo_code: locationCart.promo_code,
          custom_pickup_time: $("#custom_pickup_time").val(),
          pick_up_at_the_bar: locationCart.pick_up_at_the_bar,
          order_storage_identifier: locationCart.order_storage_identifier,
          userId,
          customer_number: locationCart.customer_id,
          table_customer_id: locationCart.table_customer_id ?? "",
          shop_type,
          split_payment_method: locationCart.payment_split_method,
          split_payment_amount: siblingInput.val(),
          table_customer_id: locationCart.table_customer_id ?? "",
        };

        if (orderStorageIds !== null) {
          newOrderData.order_storage_ids = orderStorageIds;
        }

        $.ajax({
          type: "POST",
          url: "/ajax/order/new",
          data: newOrderData,
          timeout: 120000,
        })
          .done(function (data) {
            $("div.loader").remove();
            $(".order-button").attr("disabled", false);
            $(".order-payment-split").attr("disabled", false);
            button.attr("disabled", true);
            button.removeClass("btn-primary");
            button.addClass("btn-success");
            button.css("opacity", "1");
            button.append('<i class="material-icons p-0">check_circle</i>');
            siblingInput.attr("disabled", true);
            if (
              data.payment_redirect_url !== undefined &&
              data.payment_redirect_url !== null
            ) {
              document.location.href = data.payment_redirect_url;
            }

            if (data.order_id !== null && data.order_id !== undefined) {
              $("#payment-split-modal").attr("data-order-id", data.order_id);
            }

            const paymentSplitInputs = $(".payment-split-input").toArray();

            const totalPaid = paymentSplitInputs.reduce((acc, el) => {
              return acc + (parseFloat($(el).val()) || 0);
            }, 0);

            let totalAmount = parseFloat(
              $(".payment-split-input").first().attr("data-max-allowed")
            );

            if (totalPaid > 0) {
              let remainingValue = (totalAmount * 100 - totalPaid * 100) / 100;
              $("#total-amount").text(
                `Hvile : ${formattedPriceToShow(remainingValue)}`
              );
            }
            const orderHtml =
              data.order_html !== undefined && data.order_html !== null
                ? data.order_html
                : "";
            if ($("#content").hasClass("is-employee") && orderHtml !== "") {
              $("#payment-split-modal").modal("hide");

              $("#cart-modal").modal("hide");
              $(".modal-backdrop").remove();
              $(".confirmation-toast").remove();
              $("#content").append(
                '<div class="position-fixed bottom-0 right-0 p-3 confirmation-toast" style="z-index: 1001; right: 0; bottom: 0;">' +
                  '<div role="alert" aria-live="assertive" aria-atomic="true" class="toast" data-autohide="true" data-delay="2000">' +
                  '  <div class="toast-header">' +
                  '    <strong class="mr-auto">Ordre registrert</strong>' +
                  "    <small></small>" +
                  '    <button type="button" class="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close">' +
                  '      <span aria-hidden="true">&times;</span>' +
                  "    </button>" +
                  "  </div>" +
                  '  <div class="toast-body">' +
                  orderHtml +
                  "  </div>" +
                  "</div>" +
                  "</div>"
              );
              $(".toast").toast("show");
              removeQueryParams("order");
              splitOrderId = null;
              locationCart.order_id = null;
              localStorage.removeItem("location-cart");

              passDataToWebView(
                e,
                "print_order",
                data.orderProducts,
                data.printerIP,
                data.printerType,
                data.organizer,
                data.order
              );

              if (!locationCart.table_number && data.production_print_data) {
                passDataToWebView(
                  event,
                  "print_poll_order",
                  data.production_print_data
                );
              }
              emptyCart();
            }
            order_ajax_lock = false;
          })
          .fail(function (data) {
            $("div.loader").remove();
            $(".order-button").attr("disabled", false);
            $(".order-payment-split").attr("disabled", false);
            button.text(buttonText);
            errorPopupAlert("Payment fail!");
            if (
              data.responseJSON !== undefined &&
              data.responseJSON.error_message !== undefined &&
              data.responseJSON.error_message !== null
            ) {
              if (!$("#order-button-wrapper").is(":visible")) {
                alert(data.responseJSON.error_message);
              } else {
                blinkButton(
                  $(`.terminal-button-${locationCart.terminal_id}`),
                  "red"
                );
                $(".loader").attr("hidden", true);
                prependAlert(
                  "#order-button-wrapper, #terminal-modal-body",
                  data.responseJSON.error_message,
                  "danger",
                  null,
                  "#order_register_form"
                );
              }
            }
            order_ajax_lock = false;
            cartQuickPaymentTerminalLock = false;
          })
          .always(function () {
            locationCart.order_storage_identifier = "";
            if (!$("#content").hasClass("is-employee")) {
              order_ajax_lock = false;
            }
          });
      }
      return false;
    });
  }
});

$(document).on("input", ".payment-split-input", function () {
  const input = $(this);
  const value = parseFloat(input.val()) || 0;
  const maxAllowedValue = parseFloat(input.attr("data-max-allowed"));
  const remainingCreditLimit =
    input.data("remaining-credit-limit") !== undefined
      ? parseFloat(input.data("remaining-credit-limit"))
      : null;
  const feedback = input.closest(".col-sm-9").find(".invalid-feedback");
  const button = input.closest(".col-sm-9").find(".order-payment-split");

  feedback.find("#max-value").text(maxAllowedValue);

  const paymentSplitInputs = $(".payment-split-input").not(input);
  const totalPaid = paymentSplitInputs.toArray().reduce((acc, el) => {
    return acc + (parseFloat($(el).val()) || 0);
  }, 0);

  let isInvalid = false;
  let errorMessage = "";

  if (value > maxAllowedValue) {
    isInvalid = true;
    errorMessage =
      "Verdien kan ikke overstige den totale mengden av " +
      formattedPriceToShow(maxAllowedValue) +
      " kr";
  } else if (value > parseFloat((maxAllowedValue - totalPaid).toFixed(2))) {
    isInvalid = true;
    errorMessage =
      "Verdien har overskredet den totale nødvendige mengden på " +
      (maxAllowedValue - totalPaid).toFixed(2) +
      " kr";
  } else if (remainingCreditLimit !== null && value > remainingCreditLimit) {
    isInvalid = true;
    errorMessage =
      "Verdien har overskredet den totale nødvendige mengden på " +
      remainingCreditLimit +
      " kr";
  }

  if (isInvalid) {
    feedback.removeClass("d-none");
    input.addClass("is-invalid");
    feedback.find("small").text(errorMessage);
    button.prop("disabled", true);
  } else {
    feedback.addClass("d-none");
    input.removeClass("is-invalid");
    button.prop("disabled", false);

    // let remainingValue =
    //   (maxAllowedValue * 100 - (totalPaid * 100 + value * 100)) / 100;
    // $("#total-amount").text(`Totalt : ${formattedPriceToShow(remainingValue)}`);
  }
});

function getPaymentSplitModal(
  data,
  container = "#event-public-list-organizer"
) {
  $.ajax({
    type: "POST",
    url: "/api/v1/private/order/get-payment-split-modal",
    data: data,
  })
    .done(function (data) {
      if (data.html !== undefined && data.html !== null) {
        if ($("#product-extra-modal").length) {
          $("#product-extra-modal").remove();
        }
        if ($("#cart-modal").length) {
          $("#cart-modal").remove();
        }
        if ($("#payment-split-modal").length) {
          $("#payment-split-modal").remove();
        }
        if ($(container).length) {
          $(container).after(data.html);
        }

        if ($("#payment-split-modal").length) {
          $("#payment-split-modal").modal("show");
          setTimeout(() => {
            const paymentSplitInputs = $(".payment-split-input").toArray();

            const totalPaid = paymentSplitInputs.reduce((acc, el) => {
              return acc + (parseFloat($(el).val()) || 0);
            }, 0);

            let totalAmount = parseFloat(
              $(".payment-split-input").first().attr("data-max-allowed")
            );

            if (totalPaid > 0) {
              let remainingValue = (totalAmount * 100 - totalPaid * 100) / 100;
              $("#total-amount").text(
                `Hvile : ${formattedPriceToShow(remainingValue)}`
              );
            }
          }, 200);
        }
      }
    })
    .fail(function (data) {
      if (
        data.responseJSON.redirect_url !== undefined &&
        data.responseJSON.redirect_url !== null
      ) {
        document.location.href = data.responseJSON.redirect_url;
      }
    });
}
