$(document).ready(function () {
  let p = $("body").attr("p");
  let a = $("body").attr("a");
  const locationId = $("body").attr("event_id");

  if (p === "z_report") {
    let page = 1;
    $(document).on(
      "change",
      "#bankakspet, #visa, #mastercard, #othercard, #cash",
      function (e) {
        e.preventDefault();
        let totalAmount = 0;
        let posAmount = floor($("#total-pos-amount").attr("data-amount"));

        const ids = [
          "#bankakspet",
          "#visa",
          "#mastercard",
          "#othercard",
          "#cash",
        ];
        ids.forEach(function (id) {
          let value = $(id).val();
          if (value === "") {
            totalAmount += 0;
          } else if (typeof value === "string" && value.includes(",")) {
            totalAmount += floor(value.replace(",", "."));
          } else {
            totalAmount += floor(value);
          }
        });

        let diffAmount = floor(totalAmount - posAmount);

        $("#total-amount").text(formattedPriceToShow(totalAmount));
        $("#diff-amount").text(formattedPriceToShow(diffAmount));
      }
    );

    $(document).on("click", "#register-confirm", function (e) {
      $("#z-report-form").submit();
    });

    $(document).on("submit", "#z-report-form", function (e) {
      e.preventDefault();
      const formData = new FormData($(this)[0]);
      const printZreport = $("#print-z-report").is(":checked");

      for (let [key, value] of formData.entries()) {
        // Check if the value is empty
        if (value === "") {
          formData.set(key, 0);
        } else if (typeof value === "string" && value.includes(",")) {
          formData.set(key, value.replace(",", "."));
        }
      }

      formData.append("location_id", locationId);

      $.ajax({
        type: "POST",
        url: "/api/v1/private/z-report/new",
        data: formData,
        enctype: "multipart/form-data",
        async: true,
        cache: false,
        processData: false,
        contentType: false,
        xhrFields: {
          responseType: "blob", // Important to handle binary data
        },
      })
        .done(function (data) {
          if (printZreport) {
            try {
              downloadPDF(data);
            } catch (error) {
              alert(error);
            }
          }
          setTimeout(function () {
            popupAlert();
          }, 500);
          setTimeout(function () {
            location.href = `/location/${locationId}/z-report`;
          }, 1000);
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }

          if (
            data.responseJSON.message !== undefined &&
            data.responseJSON.message !== null
          ) {
            $("#inventory-stock-history-response").empty();
            $("#inventory-stock-history-response").append(
              data.responseJSON.message
            );
          }
        });

      function downloadPDF(data) {
        const blob = new Blob([data], { type: "application/pdf" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "Z-report.pdf";
        link.click();
        URL.revokeObjectURL(url);
      }
    });

    $(document).on("click", "#z-report-download-pdf", function () {
      const zReportId = $(this).attr("data-z-report-id");

      const url = `/api/v1/private/z-report/download_pdf?z_report_id=${zReportId}&location_id=${locationId}`;
      window.location.href = url;
    });

    //start-end date filter
    $(document).on("change", "#start-date", function (e) {
      const startDate = $(this).val();
      const endDate = $("#end-date").val();

      if (checkDateValidation(startDate, endDate)) {
        getZreport(locationId, startDate, endDate);
      } else {
        $(this).focus();
        return;
      }
    });

    $(document).on("click", ".pagination a", function (e) {
      e.preventDefault();
      const filterStartDate = $("#start-date").val();
      const filterEndDate = $("#end-date").val();
      page = parseInt($(this).attr("href").substring(6));
      getZreport(locationId, filterStartDate, filterEndDate, page);
    });

    $(document).on("change", "#end-date", function (e) {
      const endDate = $(this).val();
      const startDate = $("#start-date").val();

      if (checkDateValidation(startDate, endDate)) {
        getZreport(locationId, startDate, endDate);
      } else {
        $(this).focus();
        return;
      }
    });

    function checkDateValidation(filterStartDate, filterEndDate) {
      if (!filterStartDate || !filterEndDate) {
        alert("Både startdato og sluttdato er obligatorisk.");
        return false;
      }

      if (new Date(filterEndDate) < new Date(filterStartDate)) {
        alert("Sluttdato kan ikke være før startdato.");
        return false;
      }

      return true;
    }

    function getZreport(locationId, startDate, endDate, page) {
      $.ajax({
        type: "POST",
        url: "/api/v1/private/z-report/search",
        data: {
          location_id: locationId,
          start_date: startDate,
          end_date: endDate,
          page,
        },
      })
        .done(function (data) {
          if (data.html && data.html !== null && data.html !== "") {
            $("#z-report-list-view").empty();
            $("#z-report-list-view").html(data.html);
          }
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }

          if (
            data.responseJSON.message !== undefined &&
            data.responseJSON.message !== null
          ) {
            $("#inventory-stock-history-response").empty();
            $("#inventory-stock-history-response").append(
              data.responseJSON.message
            );
          }
        });
    }

    $(document).on("click", "#download-all", function () {
      const start_date = $("#start-date").val();
      const end_date = $("#end-date").val();

      const url = `/api/v1/private/z-report/download-all?start_date=${start_date}&end_date=${end_date}&location_id=${locationId}`;
      window.location.href = url;
    });
  }
});
