$(document).ready(function () {
  let p = $("body").attr("p");
  let a = $("body").attr("a");
  let posType = $("#location-products").data("pos-type");
  if (p === "events" && posType === "scroll") {
    $(document).on("submit", ".product-search-form", function (e) {
      e.preventDefault();
      let formData = new FormData($(this)[0]);
      formData.append("pos_type", posType);
      $.ajax({
        type: "POST",
        url: "/ajax/events/get_all_products",
        data: formData,
        enctype: "multipart/form-data",
        async: true,
        cache: false,
        processData: false,
        contentType: false,
      })
        .done(function (data) {
          if (data) {
            if (data.search) {
              $(".product-group").hide();
            } else {
              $(".product-group").show();
            }
            $("#product-group-").html(data.html);
          }
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }
        });
    });

    $(document).on("click", ".category-nav-link", function (e) {
      const locationId = parseInt($("#location-products").data("location-id"));
      const formData = new FormData();
      $("#search_string").val("");
      formData.append("search_string", "");
      formData.append("event_id", locationId);
      const postype = localStorage.getItem("postype") ?? null;
      formData.append("pos_type", postype);
      $.ajax({
        type: "POST",
        url: "/ajax/events/get_all_products",
        data: formData,
        enctype: "multipart/form-data",
        async: true,
        cache: false,
        processData: false,
        contentType: false,
      })
        .done(function (data) {
          if (data) {
            if (data.search) {
              $(".product-group").hide();
            } else {
              $(".product-group").show();
            }
            $("#product-group-").html(data.html);
          }
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }
        });
    });
  }
});

function selectCategory(evt, categoryId) {
  $(".tabcontent").hide();

  $(".tablinks").removeClass("active");

  $("#category-" + categoryId).css("display", "grid");
  $(evt.currentTarget).addClass("active");
}
