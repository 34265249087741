let deviceId = localStorage.getItem("deviceID");
if (!deviceId) {
  deviceId = createDeviceId(8);
  localStorage.setItem("deviceID", deviceId);
}
setDeviceId();

// Send deviceId to the server using jQuery AJAX
function setDeviceId() {
  $.ajax({
    url: "/ajax/admin_organizers/set_device_id",
    type: "POST",
    data: { deviceID: deviceId },
  })
    .done(function (response) {
      return false;
    })
    .fail(function (xhr, status, error) {
      console.error("Error storing Device ID:", error);
    });
}

$(document).on("click", "#add-payment-method-confirm", function () {
  const newPaymentMethodName = $("#newPaymenetInput").val();

  if (!newPaymentMethodName) {
    alert("Vennligst skriv inn betalingsmidler navn!");
    $("#newPaymenetInput").focus();
    return;
  }

  $.ajax({
    type: "POST",
    url: "/api/v1/private/payment_method/new",
    data: { payment_method_name: newPaymentMethodName },
  })
    .done(function (data) {
      if (data.success) {
        $("#organizer_settings_form").submit();
      }

      $("#newPaymentModel").modal("hide");
      $(".modal-backdrop").remove();
    })
    .fail(function (data) {
      if (
        data.responseJSON.redirect_url !== undefined &&
        data.responseJSON.redirect_url !== null
      ) {
        document.location.href = data.responseJSON.redirect_url;
      }
    });
});

$(document).on("change", "#multiple-select", function () {
  var paymentTerminalValue = $("#multiple-select").attr(
    "data-payment-terminal"
  );
  var selectedValues = $(this).val();

  if (selectedValues.includes(paymentTerminalValue)) {
    $(this).find('option[value^="terminal-"]').prop("selected", true);
  }
  $(this).selectpicker("refresh");
});

$(document).ready(function () {
  let organizer_settings_ajax_lock = false;

  let posType = localStorage.getItem("postype");
  if (posType === "classic") {
    $("#pos_scroll").attr("checked", false);
    $("#pos_classic").attr("checked", true);
  } else {
    $("#pos_classic").attr("checked", false);
    $("#pos_scroll").attr("checked", true);
  }

  $("#device_id").val(deviceId);

  const mainPOSDeviceId = $("#main_pos_device_id").val();
  if (mainPOSDeviceId === deviceId) {
    $("#main_pos").prop("checked", true);
  }

  $(document).on("change", 'input[name="pos_type"]', function () {
    var selectedValue = $('input[name="pos_type"]:checked').val();
    localStorage.setItem("postype", selectedValue);
  });

  if (window.ReactNativeWebView) {
    $("#main-pos-setting").attr("hidden", false);
  }

  $(document).on("change", "#main_pos", function () {
    if ($(this).is(":checked")) {
      $("#main-pos-confirm-model").modal("show");
    } else {
      $("#main-pos-warning")
        .fadeIn(300)
        .toast({
          autohide: false,
        })
        .toast("show");

      // Set the checkbox back to checked state
      $(this).prop("checked", true);
    }
  });

  $(document).on("click", "#main-pos-warning .close", function () {
    $("#main-pos-warning").css("display", "none");
  });

  $(document).on("click", "#main-pos-confirm", function () {
    $("#main_pos").prop("checked", true);
    if (!deviceId) {
      deviceId = createDeviceId(8);
      localStorage.setItem("deviceID", deviceId);
    }
    const organizerId = $("#main_pos").attr("data-organizer-id");
    updateMainPOSDevice(deviceId, organizerId);
    $("#main-pos-confirm-model").modal("hide");
  });

  $(document).on("click", "#main-pos-cancel", function () {
    $("#main_pos").prop("checked", false);
  });

  function updateMainPOSDevice(deviceId, organizerId) {
    try {
      $.ajax({
        type: "POST",
        url: "/ajax/organizer_settings/update_main_pos_device",
        data: {
          main_pos_device_id: deviceId,
          organizer_id: organizerId,
        },
      })
        .done(function (data) {
          if (data.success) {
            popupAlert();
            return;
          } else {
            return alert(data.error);
          }
        })
        .fail(function (data) {
          return alert("Mislykkes i å angi HovedPOS");
        });
    } catch (error) {
      console.error(error);
    }
  }

  $(document).on("submit", "#organizer_settings_form", function () {
    function validateRequiredFields(form) {
      let isValid = true;
      $(form)
        .find("[required]")
        .each(function () {
          if ($(this).val().trim() === "") {
            isValid = false;
            $(this).addClass("is-invalid"); // Add an invalid class to highlight the empty fields
            // Optionally, you can show a custom message or use browser's built-in message
            $(this).next(".invalid-feedback").text("This field is required.");
          } else {
            $(this).removeClass("is-invalid");
            $(this).next(".invalid-feedback").text("");
          }
        });
      return isValid;
    }

    if (!organizer_settings_ajax_lock) {
      // Validate required fields before proceeding
      if (!validateRequiredFields(this)) {
        // Display a general error message if validation fails
        prependAlert(
          "#div_organizer_settings_button",
          "Please fill out all required fields.",
          "danger",
          null,
          "#organizer_settings_form"
        );
        $("#saveSettingModel").modal("hide");
        return false;
      }

      organizer_settings_ajax_lock = true;

      let formData = new FormData(
        document.getElementById("organizer_settings_form")
      );

      $.ajax({
        type: "POST",
        url: "/ajax/organizer_settings/update",
        data: formData,
        enctype: "multipart/form-data",
        async: true,
        cache: false,
        processData: false,
        contentType: false,
      })
        .done(function (data) {
          if (data.message !== undefined && data.message !== null) {
            prependAlert(
              "#div_organizer_settings_button",
              data.message,
              "success",
              null,
              "#organizer_settings_form"
            );
          }
          popupAlert();
          $("#saveSettingModel").modal("hide");
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }

          if (
            data.responseJSON.error_message !== undefined &&
            data.responseJSON.error_message !== null
          ) {
            prependAlert(
              "#div_organizer_settings_button",
              data.responseJSON.error_message,
              "danger",
              null,
              "#organizer_settings_form"
            );
          }

          organizer_settings_ajax_lock = false;
        })
        .always(function () {
          organizer_settings_ajax_lock = false;
        });
    }

    return false;
  });

  $(document).on("click", "#save-setting-cancel", function () {
    $("#saveSettingModel").modal("hide");
  });
  $(document).on("click", "#save-setting-confirm", function () {
    $("#organizer_settings_form").submit();
  });

  $(document).on("change", "#location_restaurant", function () {
    if ($(this).is(":checked")) {
      if ($(".location-restaurant-field-wrapper").hasClass("d-none")) {
        $(".location-restaurant-field-wrapper").removeClass("d-none");
      }
      $("#restaurant_preparation_time_kitchen").prop("required", true);
      $("#restaurant_preparation_time").prop("required", true);
    } else {
      if (!$(".location-restaurant-field-wrapper").hasClass("d-none")) {
        $(".location-restaurant-field-wrapper").addClass("d-none");
      }
      $("#restaurant_preparation_time_kitchen").val(null);
      $("#restaurant_preparation_time").val(null);
      $("#restaurant_preparation_time_kitchen").prop("required", false);
      $("#restaurant_preparation_time").prop("required", false);
    }
  });
  $(document).on("change", "#location_takeaway", function () {
    if ($(this).is(":checked")) {
      if ($(".location-takeaway-field-wrapper").hasClass("d-none")) {
        $(".location-takeaway-field-wrapper").removeClass("d-none");
      }
      $("#takeaway_preparation_time").prop("required", true);
      $("#number_of_days_you_can_order_before_pickup").prop("required", true);
    } else {
      $("#takeaway_preparation_time").val(null);
      $("#number_of_days_you_can_order_before_pickup").val(null);
      if (!$(".location-takeaway-field-wrapper").hasClass("d-none")) {
        $(".location-takeaway-field-wrapper").addClass("d-none");
      }
      $("#takeaway_preparation_time").prop("required", false);
      $("#number_of_days_you_can_order_before_pickup").prop("required", false);
    }
  });
  $(document).on("change", "#waiting_time_message", function () {
    if ($(this).is(":checked")) {
      if ($(".waiting-time-message-field-wrapper").hasClass("d-none")) {
        $(".waiting-time-message-field-wrapper").removeClass("d-none");
      }
      $("#minutes_before_waiting_time_message_activation").prop(
        "required",
        true
      );
    } else {
      $("#minutes_before_waiting_time_message_activation").val(null);
      if (!$(".waiting-time-message-field-wrapper").hasClass("d-none")) {
        $(".waiting-time-message-field-wrapper").addClass("d-none");
      }
      $("#minutes_before_waiting_time_message_activation").prop(
        "required",
        false
      );
    }
  });
  $(document).on("change", "#payment_method_coupon", function () {
    if ($(this).is(":checked")) {
      if ($(".payment-method-coupon-field-wrapper").hasClass("d-none")) {
        $(".payment-method-coupon-field-wrapper").removeClass("d-none");
      }
      $("#max_orders_per_day_per_person_with_payment_method_coupon").prop(
        "required",
        true
      );
    } else {
      $("#max_orders_per_day_per_person_with_payment_method_coupon").val(null);
      if (!$(".payment-method-coupon-field-wrapper").hasClass("d-none")) {
        $(".payment-method-coupon-field-wrapper").addClass("d-none");
      }
      $("#max_orders_per_day_per_person_with_payment_method_coupon").prop(
        "required",
        false
      );
    }
  });
  $(document).on(
    "change",
    "#location_restaurant_cart_customer_name",
    function () {
      if ($(this).is(":checked")) {
        if (
          $(
            "#location-restaurant-cart-customer-name-mandatory-wrapper"
          ).hasClass("d-none")
        ) {
          $(
            "#location-restaurant-cart-customer-name-mandatory-wrapper"
          ).removeClass("d-none");
        }
      } else {
        $("#location_restaurant_cart_customer_name_mandatory").prop(
          "checked",
          false
        );
        if (
          !$(
            "#location-restaurant-cart-customer-name-mandatory-wrapper"
          ).hasClass("d-none")
        ) {
          $(
            "#location-restaurant-cart-customer-name-mandatory-wrapper"
          ).addClass("d-none");
        }
      }
    }
  );
  $(document).on(
    "change",
    "#location_restaurant_cart_customer_phone_number",
    function () {
      if ($(this).is(":checked")) {
        if (
          $(
            "#location-restaurant-cart-customer-phone_number-mandatory-wrapper"
          ).hasClass("d-none")
        ) {
          $(
            "#location-restaurant-cart-customer-phone_number-mandatory-wrapper"
          ).removeClass("d-none");
        }
      } else {
        $("#location_restaurant_cart_customer_phone_number_mandatory").prop(
          "checked",
          false
        );
        if (
          !$(
            "#location-restaurant-cart-customer-phone_number-mandatory-wrapper"
          ).hasClass("d-none")
        ) {
          $(
            "#location-restaurant-cart-customer-phone_number-mandatory-wrapper"
          ).addClass("d-none");
        }
      }
    }
  );
  $(document).on(
    "change",
    "#recommended_max_orders_per_day_per_person_with_payment_method_coupon",
    function () {
      if (!checkMaxOrdersWithCoupon()) {
        alert(
          $("#payment-method-coupon-field-wrapper").attr(
            "data-recommended-max-coupons-message"
          )
        );
        $(this).val("");
      }
    }
  );
  $(document).on(
    "change",
    "#max_orders_per_day_per_person_with_payment_method_coupon",
    function () {
      if (!checkMaxOrdersWithCoupon()) {
        alert(
          $("#payment-method-coupon-field-wrapper").attr(
            "data-recommended-max-coupons-message"
          )
        );
        $(this).val("");
      }
      if (!checkTotalOrdersWithCoupon()) {
        alert(
          $("#payment-method-coupon-field-wrapper").attr(
            "data-max-coupons-message"
          )
        );
        $(this).val("");
      }
    }
  );
  $(document).on(
    "change",
    "#max_orders_per_day_with_payment_method_coupon",
    function () {
      if (!checkTotalOrdersWithCoupon()) {
        alert(
          $("#payment-method-coupon-field-wrapper").attr(
            "data-max-coupons-message"
          )
        );
        $(this).val("");
      }
    }
  );

  function checkMaxOrdersWithCoupon() {
    let recommendedMaxOrdersPerDayPerPerson = $(
      "#recommended_max_orders_per_day_per_person_with_payment_method_coupon"
    ).val();
    let maxOrdersPerDayPerPerson = $(
      "#max_orders_per_day_per_person_with_payment_method_coupon"
    ).val();
    if (
      recommendedMaxOrdersPerDayPerPerson != "" &&
      maxOrdersPerDayPerPerson != "" &&
      !isNaN(recommendedMaxOrdersPerDayPerPerson) &&
      !isNaN(maxOrdersPerDayPerPerson) &&
      recommendedMaxOrdersPerDayPerPerson > maxOrdersPerDayPerPerson
    ) {
      return false;
    }
    return true;
  }

  function checkTotalOrdersWithCoupon() {
    let maxOrdersPerDayPerPerson = parseInt(
      $("#max_orders_per_day_per_person_with_payment_method_coupon").val()
    );
    let totalOrdersPerDayPerPerson = parseInt(
      $("#max_orders_per_day_with_payment_method_coupon").val()
    );
    if (
      totalOrdersPerDayPerPerson != "" &&
      maxOrdersPerDayPerPerson != "" &&
      !isNaN(totalOrdersPerDayPerPerson) &&
      !isNaN(maxOrdersPerDayPerPerson) &&
      maxOrdersPerDayPerPerson > totalOrdersPerDayPerPerson
    ) {
      return false;
    }
    return true;
  }

  $(document).on("click", ".find-printer", function (e) {
    e.preventDefault();
    const location_id = $(this).data("location-id");

    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ action: "discovery" })
      );
    }

    document.addEventListener("message", function (event) {
      savedFoundPrinters(event, location_id);
    });
    window.addEventListener("message", function (event) {
      savedFoundPrinters(event, location_id);
    });

    $.ajax({
      type: "POST",
      url: "/ajax/printer/get_find_printer_modal",
      data: { location_id },
    })
      .done(function (data) {
        if (data.html !== undefined && data.html !== null) {
          $("#printer-setting").after(data.html);
          $("#find-printer-modal").modal("show");
        }
      })
      .fail(function (data) {
        if (
          data.responseJSON.redirect_url !== undefined &&
          data.responseJSON.redirect_url !== null
        ) {
          document.location.href = data.responseJSON.redirect_url;
        }
      });

    $(document).on("click", ".add-new-printer", function () {
      const currEle = $(this);
      const location_id = $(this).data("location-id");
      const name = $(this).data("name");
      const ip_address = $(this).data("printer-ip");
      const type = $(this).data("printer-type");

      $.ajax({
        type: "POST",
        url: "/ajax/printer/new",
        data: { location_id, name, ip_address, status: 1, type },
      })
        .done(function (data) {
          currEle.closest("tr").fadeOut(1000);

          setTimeout(() => {
            currEle.closest("tr").remove();
            if ($(".printer-row").length <= 0) {
              $("#find-printer-modal").modal("hide");
              $(".modal-backdrop").remove();
              setTimeout(() => {
                $("#find-printer-modal").remove();
              }, 1000);
            }

            if ($("#printer-list-tbody").length > 0 && data.printer) {
              const newRow = generatePrinterRow(data.printer);
              $("#printer-list-tbody").append(newRow);
            }
          }, 1000);
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }
        });
    });
  });

  if (!window.ReactNativeWebView) {
    $(".find-printer").remove();
  }

  function generatePrinterRow(printer) {
    return `
      <tr id="printer-${printer.printer_id}">
        <td>${printer.name}</td>
        <td>${printer.ip_address}</td>
        <td>${printer.type}</td>
        <td class="d-flex justify-content-end">
          <button class="btn btn-primary mr-4 test-print" 
                  onclick="passDataToWebView(event, 'test', [], '${printer.ip_address}', '${printer.type}')">
            Print
          </button>
          <button type="button" 
                  class="btn printer-delete-btn btn-primary mr-4" 
                  data-toggle="modal" 
                  data-target="#printerDeleteConfirmationModal" 
                  data-delete-id="${printer.printer_id}">
            Slett
          </button>
          <a href="/location/${printer.location_id}/printer/${printer.printer_id}/update" class="btn">
            <i class="material-icons">settings</i>
          </a>
        </td>
      </tr>
    `;
  }
});

function savedFoundPrinters(event, location_id) {
  const printers = JSON.parse(event.data);
  
  if (printers) {
    if (printers.length > 0) {
      $.ajax({
        type: "POST",
        url: "/ajax/printer/get_discovered_printers",
        data: { location_id, printers },
      })
        .done(function (data) {
          if (data.html !== undefined && data.html !== null) {
            $("#printer-modal-body").empty();
            $("#printer-modal-body").append(data.html);
          }
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }
        });
    } else {
      $("#find-printer-modal").modal("hide");
      clearTimeout(window.printerTimeout);

      window.printerTimeout = setTimeout(() => {
        $("#find-printer-modal").remove();
        alert("Finner ingen skriver");
      }, 250);
    }
  }
}
