$(document).ready(function () {
  let p = $("body").attr("p");
  let locationId = $("body").attr("event_id");
  let trunOverExMva = 0;
  let yesterdayExVatTurnoverSum = 0;
  let isOpening = true;
  const isTablet = !!window.ReactNativeWebView;

  moment.tz.setDefault("Europe/Oslo");
  if (p === "organizer_location_dashboard") {
    function fitToViewport() {
      $("#content").height($(window).height());
    }

    fitToViewport();

    // Adjust on window resize
    $(window).resize(function () {
      fitToViewport();
    });
    moment.updateLocale("en", {
      week: {
        dow: 1, // Monday is the start of the week
      },
      weekdays: "Søndag_Mandag_Tirsdag_Onsdag_Torsdag_Fredag_Lørdag".split("_"),
    });
    let start_date = moment().format("YYYY-MM-DD") + "T00:00";
    let end_Date = moment().format("YYYY-MM-DD") + "T23:59";
    let isIncludingTableTurnOver = 0;
    let isIncludingCreditOrder = 0;
    let weeks = 1;
    let months = 1;
    $(document).on("click", "#location-dashboard-turnover-sum", function (e) {
      e.preventDefault();

      window.location.href = `/location/${locationId}/statistics?filter_by=day&custom_period=day&prev_selected_period=day&start_date=${start_date}&end_date=${end_Date}`;
    });
    const currentDay = moment().day();

    // Calculate the day of the week for last week
    const currentDayName = moment().day(currentDay).format("dddd");

    function pieChart(container, primalData, title) {
      const tooltipLabelText =
        container == "location-dashboard-pie-vat" ? "varer" : "ordrer";

      Highcharts.chart(container, {
        chart: {
          type: "variablepie",
          height: isTablet ? 80 : 100,
          style: {
            width: "100%",
          },
          marginLeft: -120,
          marginRight: 5,
        },

        title: false,
        exporting: { enabled: false },
        credits: { enabled: false },
        tooltip: {
          headerFormat: "",
          pointFormat:
            '<span style="color:{point.color}">\u25CF</span> <b> {point.name}</b><br/>' +
            "Sum(eks mva): <b>{formattedY}</b><br/>" +
            "Antall " +
            tooltipLabelText +
            ": <b>{point.z}</b><br/>",
          formatter: function () {
            const formattedY = formattedPriceToShow(this.y);
            return (
              '<span style="color:' +
              this.point.color +
              '">\u25CF</span> <b>' +
              this.point.name +
              "</b><br/>" +
              "Sum(eks mva): <b>" +
              formattedY +
              "</b><br/>" +
              "Antall " +
              tooltipLabelText +
              ": <b>" +
              this.point.z +
              "</b><br/>"
            );
          },
          style: {
            fontSize: "11px",
            width: 100,
          },
          backgroundColor: "rgba(255, 255, 255, 1)", // Solid white background
          borderRadius: 5, // Rounded corners
          borderWidth: 1, // Add border for better visibility
          borderColor: "#ccc", // Light gray border
          shadow: true, // Add shadow for depth
          zIndex: 10,
          positioner: function (labelWidth, labelHeight) {
            const chart = this.chart;
            const centerX = chart.plotLeft + chart.series[0].center[0]; // X-center of the pie
            const centerY = chart.plotTop + chart.series[0].center[1]; // Y-center of the pie
            const radius = chart.series[0].center[2] / 2; // Radius of the pie chart

            const tooltipX = centerX + radius + 3; // Place it to the right, just outside the pie
            const tooltipY = centerY - labelHeight / 2; // Center it vertically with the pie
            return { x: tooltipX, y: tooltipY };
          },
          borderWidth: 0,
        },
        plotOptions: {
          variablepie: {
            allowPointSelect: true,
            cursor: "pointer",
            dataLabels: {
              enabled: false,
            },
            showInLegend: true,
            enableMouseTracking: true,
            point: {
              events: {
                mouseOver: function () {
                  // Reduce opacity of the corresponding legend item
                  const legendItems = this.series.chart.legend.allItems;
                  legendItems.forEach((item) => {
                    item.legendItem.element.style.opacity = 0.1;
                    item.legendItem.element.style.transition = "opacity 0.5s";
                  });
                },
                mouseOut: function () {
                  // Restore opacity when the mouse leaves
                  const legendItems = this.series.chart.legend.allItems;
                  legendItems.forEach((item) => {
                    item.legendItem.element.style.transition = "opacity 1s";
                    item.legendItem.element.style.opacity = 1;
                  });
                },
              },
            },
          },
        },
        series: [
          {
            minPointSize: 10,
            innerSize: "20%",
            zMin: 0,
            name: "countries",
            data: primalData,
          },
        ],
        legend: {
          layout: "vertical",
          align: "right",
          verticalAlign: "middle",
          floating: false,
          useHTML: true,
          labelFormatter: function () {
            return `
            <div style="text-align: left; width: 100px; word-wrap: break-word; white-space: normal;">
            ${this.name}
           </div>`;
          },
        },
      });
    }

    function eventBarChart(primalData) {
      Highcharts.chart("event-bar-graph", {
        chart: { type: "column", height: isTablet ? 150 : 200 },
        exporting: { enabled: false },
        title: false,
        xAxis: {
          categories: primalData.date,
          title: {
            text: $("#admin-dashboard-bar-graph").attr("data-x-axis-text"),
          },
        },
        yAxis: {
          title: {
            text: $("#admin-dashboard-bar-graph").attr("data-y-axis-text"),
          },
        },
        credits: { enabled: false },
        series: [
          {
            showInLegend: false,
            data: isOpening ? primalData.revenue : primalData.yesterdayrevenue,
            name: "Omsetning ink. mva.",
          },
        ],
        tooltip: {
          pointFormatter: function () {
            const formattedValue = formattedPriceToShow(this.y);
            return `${this.series.name}: <b>${formattedValue}</b><br/>`;
          },
          shared: true,
        },
      });
    }

    function eventLineChart(currentData) {
      Highcharts.chart("event-line-graph", {
        chart: { height: isTablet ? 150 : 200, marginBottom: 70 },
        exporting: { enabled: false },
        title: false,

        plotOptions: {
          series: {},
        },
        xAxis: {
          categories: currentData.date,
          labels: {
            rotation: 0, // Setting X-axis label rotation to 0 degrees
          },
        },
        yAxis: {
          title: {
            text: $("#admin-dashboard-line-graph").attr("data-y-axis-text"),
          },
        },
        credits: { enabled: false },
        series: [
          {
            name: isOpening ? "Omsetning i dag." : "Omsetning i går",
            data: isOpening
              ? currentData.revenue
              : currentData.yesterdayrevenue,
            color: "#2EADF4",
          },
          {
            name: `Omsetning forrige ${currentDayName} (NOK ${formattedPriceToShow(
              floor(currentData.lastWeekDateTrunover)
            )} inkl mva)`,
            data: currentData.lastWeekDaterevenue,
            color: "#D3D3D3",
          },
        ],
        tooltip: {
          pointFormatter: function () {
            const formattedValue = formattedPriceToShow(this.y);
            return `${this.series.name}: <b>${formattedValue}</b><br/>`;
          },
          shared: true,
        },
      });
    }

    function columnChart(
      currentWeeklyTotalTurnOver,
      previousWeeklyTotalTurnOver,
      avrageWeeklyTotalTurnOver,
      currentMonthTotalTurnover,
      previousMonthTotalTurnover,
      averageMonthTotalTurnover,
      weeklySalePrediction,
      monthlySalePrediction
    ) {
      let biggestValue = Math.max(
        currentWeeklyTotalTurnOver,
        previousWeeklyTotalTurnOver,
        avrageWeeklyTotalTurnOver,
        currentMonthTotalTurnover,
        previousMonthTotalTurnover,
        averageMonthTotalTurnover
      );

      let firstDateOfMonth =
        moment().startOf("month").format("YYYY-MM-DD") + "T00:00";
      let lastDateOfMonth =
        moment().endOf("month").format("YYYY-MM-DD") + "T23:59";
      let firstDateOfWeek =
        moment().startOf("week").format("YYYY-MM-DD") + "T00:00";
      let lastDateOfWeek =
        moment().endOf("week").format("YYYY-MM-DD") + "T23:59";

      Highcharts.chart("bar-chart", {
        chart: { type: "column", height: 150 },
        exporting: { enabled: false },
        title: false,
        xAxis: {
          categories: ["UKE", "MND"],
          title: {
            text: $("#admin-dashboard-bar-graph").attr("data-x-axis-text"),
          },
        },
        yAxis: {
          min: 0,
          max: biggestValue,
          endOnTick: false,
          title: false,
          labels: {
            formatter: function () {
              if (this.value >= 1000) {
                return (this.value / 1000).toFixed(0) + "k"; // Convert to 'k' notation if value is >= 1000
              }
              return this.value; // Otherwise, return the original value
            },
          },
        },
        credits: { enabled: false },
        plotOptions: {
          column: {
            pointWidth: 20,
            events: {
              click: function (event) {
                // Access the clicked column data here
                let clickedColumnData =
                  event.point.series.data[event.point.index];
                // Redirect to the "/statistic" route
                window.location.href = `/location/${locationId}/statistics?filter_by=${
                  clickedColumnData.category == "UKE" ? "week" : "month"
                }&custom_period="day"&prev_selected_period=${
                  clickedColumnData.category == "UKE" ? "week" : "month"
                }&start_date=${
                  clickedColumnData.category == "UKE"
                    ? firstDateOfWeek
                    : firstDateOfMonth
                }&end_date=${
                  clickedColumnData.category == "UKE"
                    ? lastDateOfWeek
                    : lastDateOfMonth
                }`;
              },
            },
          },
        },

        series: [
          {
            showInLegend: false,
            data: [currentWeeklyTotalTurnOver, currentMonthTotalTurnover],
            name: "Denne",
          },
          {
            showInLegend: false,
            data: [previousWeeklyTotalTurnOver, previousMonthTotalTurnover],
            name: "Forrige",
          },
          {
            showInLegend: false,
            data: [avrageWeeklyTotalTurnOver, averageMonthTotalTurnover],
            name: "Gjennomsnitts",
          },
        ],
        tooltip: {
          formatter: function () {
            if (this.series.name === "Gjennomsnitts") {
              return (
                (this.point.category === "UKE"
                  ? `Siste ${weeks} uker gjennomsnitt : `
                  : `Siste ${months} måneder gjennomsnitt : `) +
                formattedPriceToShow(this.y)
              );
            } else {
                return (
                this.series.name +
                " " +
                (this.point.category === "UKE" ? "uke" : "måned") +
                " : " +
                `<b>${formattedPriceToShow(this.y)}</b>${
                  weeklySalePrediction &&
                  this.series.name === "Denne" &&
                  this.point.category === "UKE"
                  ? `<br/>Prognose : <b>${formattedPriceToShow(
                    weeklySalePrediction
                    )}</b>`
                  : ""
                }${
                  monthlySalePrediction &&
                  this.series.name === "Denne" &&
                  this.point.category === "MND"
                  ? `<br/>Prognose : <b>${formattedPriceToShow(
                    monthlySalePrediction
                    )}</b>`
                  : ""
                }`
                );
            }
          },
        },
      });
    }

    $(document).on("show.bs.collapse", "#checkboxContainer", function () {
      $('[data-target="#checkboxContainer"] .rotate-icon').addClass("rotated");
    });
    $(document).on("hide.bs.collapse", "#checkboxContainer", function () {
      $('[data-target="#checkboxContainer"] .rotate-icon').removeClass(
        "rotated"
      );
    });

    $(document).on("change", "#include_table_turnover", function () {
      isIncludingTableTurnOver = $(this).is(":checked") ? 1 : 0;
      ajaxGetStatisticsData();
    });
    $(document).on("change", "#included_credit_order", function () {
      isIncludingCreditOrder = $(this).is(":checked") ? 1 : 0;
      ajaxGetStatisticsData();
    });
    function ajaxGetStatisticsData() {
      $.ajax({
        type: "POST",
        url: "/ajax/organizer_location_dashboard/get_statistic",
        data: {
          location_id: $("body").attr("event_id"),
          isIncludingTableTurnOver,
          isIncludingCreditOrder,
        },
      })
        .done(function (data) {
          let currentTime = moment().valueOf();
          let currentWeeklyTotalTurnOver = 0;
          let previousWeeklyTotalTurnOver = 0;
          let avrageWeeklyTotalTurnOver = 0;
          let currentMonthTotalTurnover = 0;
          let previousMonthTotalTurnover = 0;
          let averageMonthTotalTurnover = 0;
          trunOverExMva = 0;
          if (data) {
            const openingTime = moment(
              data.start_date,
              "YYYY-MM-DD HH:mm:ss"
            ).valueOf();

            isOpening = currentTime > openingTime;
          }

          if (parseFloat(data.ex_vat_turnover_sum) > 0) {
            trunOverExMva = parseFloat(data.ex_vat_turnover_sum);
          }

          if (parseFloat(data.yesterday_ex_vat_turnover_sum) > 0) {
            yesterdayExVatTurnoverSum = parseFloat(
              data.yesterday_ex_vat_turnover_sum
            );
          }

          currentWeeklyTotalTurnOver = parseFloat(
            data.currentWeeklyTotalTurnOver
          );
          previousWeeklyTotalTurnOver = parseFloat(
            data.previousWeeklyTotalTurnOver
          );

          currentMonthTotalTurnover = parseFloat(
            data.currentMonthTotalTurnover
          );
          previousMonthTotalTurnover = parseFloat(
            data.previousMonthTotalTurnover
          );

          avrageWeeklyTotalTurnOver = parseFloat(
            data.averageWeeklyTotalTurnover
          );
          averageMonthTotalTurnover = parseFloat(
            data.averageMonthlyTotalTurnover
          );

          let formatedTurnover = formattedPriceToShow(data.turnover_sum);

          //daily turnover
          if (!isOpening) {
            $("#turnover-title").text("Omsetning i går").fadeIn(400);
          }
          $("#location-dashboard-turnover-sum #turnover-title-period")
            .text(isOpening ? data.period_label : data.yesterday_period_label)
            .fadeIn(400);
          $("#location-dashboard-turnover-sum #turnover-sum")
            .hide()
            .text(
              (isOpening ? formatedTurnover : data.yesterday_turnover_sum)
                .toString()
                .replace(".", ",")
            )
            .fadeIn(400);

          $("#location-dashboard-turnover-sum #turnover-sum-ex-vat")
            .hide()
            .html(
              formattedPriceToShow(
                isOpening
                  ? trunOverExMva +
                      (isIncludingTableTurnOver
                        ? data.turnover_ex_vat_from_prodcut_table
                        : 0)
                  : yesterdayExVatTurnoverSum
              ) +
                " " +
                "eks mva"
            )
            .fadeIn(400);

          pieChart(
            "location-dashboard-pie-vat",
            isOpening ? data.pie_vat_data : data.pie_vat_data_of_yesterday,
            data.revenue_sum_formatted
          );
          pieChart(
            "location-dashboard-pie-payment-option",
            isOpening
              ? data.pie_payment_option_data
              : data.pie_payment_option_data_of_yesterday,
            data.revenue_sum_formatted
          );
          eventBarChart(data);
          eventLineChart(data);
          columnChart(
            currentWeeklyTotalTurnOver,
            previousWeeklyTotalTurnOver,
            avrageWeeklyTotalTurnOver,
            currentMonthTotalTurnover,
            previousMonthTotalTurnover,
            averageMonthTotalTurnover,
            data.weekly_sale_prediction ? data.weekly_sale_prediction : null,
            data.monthly_sale_prediction ? data.monthly_sale_prediction : null
          );
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }
        });
    }

    ajaxGetStatisticsData();
  }
});
