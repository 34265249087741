$(document).ready(function () {
  let filterByFromQuery = $("body").attr("filter_by") || null;
  let startDateFromQuery = $("body").attr("start_date") || null;
  let endDateFromQuery = $("body").attr("end_date") || null;
  let customPeriodFromQuery = $("body").attr("custom_period") || null;
  let prevSelectedPeriodFromQuery =
    $("body").attr("prev_selected_period") || null;

  if (p === "statistic_coverage_contribution") {
    setDateAndPeriod(
      startDateFromQuery,
      endDateFromQuery,
      filterByFromQuery,
      customPeriodFromQuery,
      prevSelectedPeriodFromQuery
    );
    handleCustomerIdChange(getDgAndDb);
    handleCustomPeriodChange(getDgAndDb);
    handleTopProductGroupChange(getDgAndDb);
    handleSupplierIdChange(getDgAndDb);
    handleProductGroupChange(getDgAndDb);
    handleUserChange(getDgAndDb);
    handlePlatformChange(getDgAndDb);
    handlePeriodChange(getDgAndDb);
    handleDateAndTimeChange(getDgAndDb);
    handlePreviousButton(getDgAndDb);
    handleNextButton(getDgAndDb);
    handleCompareByChange(getDgAndDb);
    handleProductChange(getDgAndDb);

    function eventLineChart(categoryArray, seriesData1, seriesData2) {
      // Render the chart
      Highcharts.chart("event-line-graph", {
        chart: {
          type: "column",
        },
        title: {
          text: null,
        },
        exporting: { enabled: false },
        plotOptions: {
          series: {
            lineWidth: 4, // Adjust line width as needed
          },
        },
        xAxis: {
          categories: categoryArray,
          labels: {
            formatter: function () {
              if (startDate.split(" ")[0] === endDate.split(" ")[0]) {
                return this.value;
              } else if (customPeriod === "week") {
                return "Uke " + this.value;
              } else if (customPeriod === "month") {
                return Highcharts.dateFormat(
                  "%B %Y",
                  new Date(this.value).getTime()
                );
              } else {
                return Highcharts.dateFormat(
                  "%e/%b",
                  new Date(this.value).getTime()
                );
              }
            },
          },
        },
        credits: { enabled: false },
        yAxis: {
          title: {
            text: "Ordrebeløp",
          },
        },
        series:
          seriesData1.length === 0 && seriesData2.length === 0
            ? [
                {
                  name: "TotalDB",
                  data: categoryArray.map((value) => [value, 0]), // Ensure x-axis categories display with 0 values
                },
              ]
            : [
                {
                  name: "TotalDB",
                  data: seriesData1.map((value, index) => [
                    categoryArray[index],
                    value,
                  ]),
                },
                {
                  name:
                    "TotalDB forrige " +
                    getPeriodTranslation(compareBy ?? "", startDate),
                  data: seriesData2.map((value, index) => [
                    categoryArray[index],
                    value,
                  ]),
                },
              ],
        tooltip: {
          formatter: function () {
            var tooltipText;
            if (startDate.split(" ")[0] === endDate.split(" ")[0]) {
              // Default formatting for datetime xAxis
              tooltipText =
                "<b>" +
                Highcharts.dateFormat("%H:%M, %b %e", this.x) +
                "</b><br/>" +
                "TotalDB " +
                formatNumber(this.y);
            } else if (customPeriod === "week") {
              tooltipText =
                "<b>" +
                "Uke" +
                " " +
                this.x +
                "</b><br/>" +
                "TotalDB " +
                formatNumber(this.y);
            } else if (customPeriod === "month") {
              tooltipText =
                "<b>" +
                Highcharts.dateFormat("%B - %Y", this.x) +
                "</b><br/>" +
                "TotalDB " +
                formatNumber(this.y);
            } else {
              tooltipText =
                "<b>" +
                Highcharts.dateFormat("%A, %b %e, %Y", this.x) +
                "</b><br/>" +
                "TotalDB " +
                formatNumber(this.y);
            }
            return tooltipText;
          },
        },
      });
    }

    function getDgAndDb(
      startDate,
      endDate,
      period = null,
      supplierId = null,
      productGroupUniqueId = null,
      locationId = null,
      userId = null,
      platform = "all",
      customPeriod,
      topProductGroupUniqueId = 0,
      customerId = 0,
      compareBy = null,
      productId = 0
    ) {
      $.ajax({
        type: "POST",
        url: "/ajax/statistic_coverage_contribution/search",
        data: {
          startDate,
          endDate,
          period,
          supplierId,
          productGroupUniqueId,
          locationId,
          userId,
          platform,
          customPeriod,
          topProductGroupUniqueId,
          customerId,
          compareBy,
          productId,
        },
      })
        .done(function (data) {
          if (data.totalDG && data.totalDB) {
            $("#key-figure-dg").text(floor(data.totalDG));
            $("#key-figure-db").text(floor(data.totalDB));
          } else {
            $("#key-figure-dg").text("0");
            $("#key-figure-db").text("0");
          }
          if (data.data) {
            const { xAxisArray, seriesData1, seriesData2 } = data.data;
            eventLineChart(xAxisArray, seriesData1, seriesData2);
          }

          let aTags = $(".statistic-tab-group a");
          aTags.each(function () {
            let oldHref = $(this).attr("href").split("?")[0];
            let newHref =
              oldHref +
              `?filter_by=${period}&custom_period=${customPeriod}&prev_selected_period=${prevSelectedPeriod}&start_date=${startDate}&end_date=${endDate}`;
            $(this).prop("href", newHref);
          });
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }
        });
    }

    getDgAndDb(
      startDate,
      endDate,
      period,
      supplierId,
      productGroupUniqueId,
      locationId,
      userId,
      platform,
      customPeriod,
      topProductGroupUniqueId,
      customerId,
      productId
    );
    toggleCustomDatePicker();
  }
});
